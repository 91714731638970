import React, {useState} from 'react'
import {landlordService} from "../../services/landlordService";
import {authService} from "../../services/authService";
import { OverlayTrigger, Tooltip} from "react-bootstrap";
import moment from "moment";
import {Link} from "react-router-dom";
import DataCardTable from "../components/DataCardTable";
import LandlordCard from "../components/LandlordCard";
import LandlordEditForm from "../components/LandlordEditForm";
import cogoToast from "cogo-toast";
import {useDialogContext} from "../App";
import PageWrapper from "../components/PageWrapper";


const Landlords = () => {

    const {setModal} = useDialogContext();
    const [isChecked, setIsChecked] = useState();

    const getDefaultFiscalInfo = (landlord) => {
        return (landlord.multipleFiscalInfo || []).find(info => info.default) || {};
    }

    const getDefaultBankInfo = (landlord) => {
        return (landlord.multipleBankInfo || []).find(info => info.default) || {};
    }

    const dataColumns = [
        {
            name: 'ID',
            grow: 0.7,
            selector: row => row.id,
            cell: row => <div data-tag="allowRowEvents">{"#" + row.id?.split("-")[0]}</div>
        },
        {
            name: 'Name',
            grow: 1.5,
            selector: row => row.name,
            cell: row => <div className={"text-capitalize"}><Link to={`/landlords/${row.id}`}>{row.name}</Link></div>
        },
        {
            name: 'Email',
            grow: 3.5,
            selector: row => row.email
        },
        {
            name: 'Phone',
            selector: row => row.dialCode + " " + row.phone,
            cell: row => row.dialCode + " " + row.phone
        },
        {
            name: 'Last Login',
            selector: row => row.lastLogin,
            cell: row => row.lastLogin ? moment(row.lastLogin).format("DD/MM/YY HH:mm") : "-"
        },
        {
            name: 'Active Listings',
            center: true,
            selector: row => row.activeListings,
            cell: row => (row.activeListings)? row.activeListings : "0"
        },
        {
            name: 'Total Requests',
            center: true,
            selector: row => row.reservations,
            cell: row => (row.reservations)? row.reservations : "0"
        },
        {
            name: 'Confirmed Requests',
            center: true,
            selector: row => row.acceptedBookings,
            cell: row => (row.acceptedBookings)? row.acceptedBookings : "0"
        },
        {
            name: 'Last 6 Months',
            center: true,
            selector: row => row.reservationsLastSixMonths,
            cell: row => (row.reservationsLastSixMonths)? row.reservationsLastSixMonths : "0"
        },
        {
            name: 'Answer Ratio',
            center: true,
            selector: row => row.answerRatio,
            cell: row => (row.answerRatio)? row.answerRatio + "%" : "-"
        },
        {
            name: 'Accepted Rate',
            center: true,
            selector: row => row.acceptedRatio,
            cell: row => (row.acceptedRatio)? row.acceptedRatio + "%" : "-"
        },
        {
            name: 'Registration Date',
            minWidth: "130px",
            selector: row => row.registrationDate,
            cell: row => <OverlayTrigger
                overlay={<Tooltip>Hora: {moment(row.registrationDate).format("HH:mm")}</Tooltip>}>
                <span>{moment(row.registrationDate).format("DD-MM-YYYY")}</span>
            </OverlayTrigger>
        },
        {
            name: 'Top Landlord',
            center: true,
            selector: row => row.topLandlord,
            cell: row => (row.topLandlord === "true" || row.topLandlord === true) ? "Yes" : "No"
        },
        {
            name: 'IBAN',
            minWidth: "200px",
            selector: row => row.multipleBankInfo,
            cell: row => getDefaultBankInfo(row)?.iban
        },
        {
            name: 'Commission',
            selector: row => row.commission,
            cell: row => <OverlayTrigger overlay={<Tooltip>Model: {row.firstRentCommission ? "Over first rent" : "Over contract of stay"}</Tooltip>}>
                <span>{row.noCommission ? 0 : row.commission}%</span>
            </OverlayTrigger>
        },
        {
            name: 'Commission Model',
            minWidth: "200px",
            selector: row => row.commission,
            cell: row => row.firstRentCommission ? "Over first rent" : "Over contract value"
        },
        {
            name: 'Promo Commission',
            selector: row => row.promotion,
            cell: row => <span>{row.promotion?.commission ? row.promotion?.commission + "%" : "-"}</span>
        },
        {
            name: 'Promo Until',
            selector: row => row.promotion,
            cell: row => <span>{row.promotion?.endDate ? moment(row.promotion?.endDate).format("DD/MM/YYYY") : "-"}</span>
        },
        {
            name: 'Fiscal Name',
            minWidth: "200px",
            grow: 3.2,
            selector: row => row.multipleFiscalName,
            cell: row => (<div data-tag="allowRowEvents" className={"property-cell"}>
                <span>{getDefaultFiscalInfo(row)?.fiscalName}</span>
                <small>{getDefaultFiscalInfo(row)?.address}</small>
            </div>)
        },
        {
            name: 'Notes',
            minWidth: "140px",
            selector: row => row.notes,
            cell: row => <textarea rows="2" defaultValue={row?.notes}/>
        },
        {
            name: 'Actions',
            button: true,
            cell: row => <div className={"actions-container"}>
                <OverlayTrigger overlay={<Tooltip>Edit</Tooltip>}>
                    <i onClick={editLandlordModal(row)}
                       className={"mdi mdi-pencil-box-outline text-primary"}/>
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>{row.deleted ? "Activate" : "Delete"}</Tooltip>}>
                    <i onClick={row.deleted ? activateLandlordHandler(row) : disableLandlordHandler(row)} className={row.deleted ? "mdi mdi-check-circle action-accept" : "mdi mdi-trash-can-outline action-reject"}/>
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Impersonate</Tooltip>}>
                    <i onClick={impersonateLandlord(row)} className={"mdi mdi-account-child"}/>
                </OverlayTrigger>
            </div>
        }

    ];


    const editLandlordModal = (landlord) => () => {
        let _editLandlord = {}
        setIsChecked((landlord.topLandlord === "true" || landlord.topLandlord === true));
        setModal({
            title: landlord.name+"'s information",
            size: "lg",
            message: <LandlordEditForm editLandlord={landlord} setEditLandlord={(cb)=>_editLandlord = cb(_editLandlord)} isChecked={isChecked} setIsChecked={setIsChecked}/> ,
            onSubmit: async () => {
                let password;
                if(_editLandlord?.commission || _editLandlord?.hasOwnProperty("firstRentCommission") || _editLandlord?.multipleBankInfo || _editLandlord.multipleFiscalInfo) password = window.prompt("Password:")
                let response = await landlordService.editLandlord(landlord.id, _editLandlord, password);
                setModal(m => ({...m, hide: true}));
                if(response.error) {
                    cogoToast.error(response.error, {
                        hideAfter: 5,
                        position: 'top-center',
                        heading: 'Error'
                    })
                } else {
                    landlord={...landlord, ..._editLandlord};
                    cogoToast.info('Landlord edited successfully!', {
                        hideAfter: 5,
                        position: 'top-center',
                        heading: 'Success'
                    });
                }

            }

        })
    };

    const activateLandlordHandler = (landlord) => async () => {
        setModal({
            title: "Activate Landlord's Account",
            size: "lg",
            message: <>Activate <strong className="text-capitalize">{landlord.name}'s</strong> account?</> ,
            onSubmit: async () => {
                let response = await landlordService.activateLandlord(landlord.id);
                setModal(m => ({...m, hide: true}));
                if(response.error) {
                    cogoToast.error(response.error, {
                        hideAfter: 5,
                        position: 'top-center',
                        heading: 'Error'
                    })
                    landlord.deleted = false;
                } else {
                    cogoToast.info('Landlord activated successfully!', {
                        hideAfter: 5,
                        position: 'top-center',
                        heading: 'Success'
                    });
                }
            }
        })
    }
    const disableLandlordHandler = (landlord) => () =>{
        setModal({
            title: "Delete Landlord",
            size: "lg",
            message: <>Delete <strong className="text-capitalize">{landlord.name}'s</strong> account?</> ,
            onSubmit: async () => {
                let response = await landlordService.deleteLandlord(landlord.id);
                setModal(m => ({...m, hide: true}));
                if (response.status === "success") {
                    cogoToast.info('Landlord deleted successfully!', {
                        hideAfter: 5,
                        position: 'top-center',
                        heading: 'Success'
                    })
                    landlord.deleted = true;
                } else {
                    cogoToast.error(response.error || "Something went wrong", {
                        hideAfter: 5,
                        position: 'top-center',
                        heading: 'Error'
                    })
                }

            }

        })
    }

    const impersonateLandlord = (landlord) => async () => {
        let token = await authService.impersonate("landlord", landlord.id);
        window.open(process.env.REACT_APP_LANDLORD_URL + "/backoffice/impersonate?type=landlord&token=" + token, '_blank').focus();
    }

    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem("landlords_filters")) || {});


    return (
        <PageWrapper breadcrumbs={[{title: "Landlords"}]}
                     title={"Landlords"} filters={filters} setFilters={setFilters}>
            <DataCardTable cardRendererComponent={LandlordCard}
                           cardRendererProps={{impersonateLandlord}}
                           columns={dataColumns}
                           dataType={"landlords"}
                           fetchData={landlordService.list}
                           filters={filters}
            />
        </PageWrapper>
    )

}

export default Landlords;
