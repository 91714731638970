import {get, patch, post} from "./index.js";


const getOperators = async () => {
    return await get("/backoffice/operators")
}

const getPermissions = async () => {
    return await get("/backoffice/permissions")
}

const changePermissions = async (newPermissions) => {
    return await post ("/backoffice/permissions", {"permissions": newPermissions})
}

const createOperator = async (newOperator) => {
    return await post ("/backoffice/createOperator", {...newOperator})

}

const editOperator = async (operator) => {
    return await post ("/backoffice/editOperator", operator)
}

const editOperatorPhoto = async (photo) => {
    return await post ("/backoffice/operatorPhoto", photo)
}



export const operatorService = {
    getOperators,
    getPermissions,
    editOperator,
    editOperatorPhoto,
    changePermissions,
    createOperator
}