import {get, post} from "./index.js";

const NAMESPACE = "/backoffice/seo"

async function getFriendlyUrls() {
    try {
        return await get(NAMESPACE + '/friendlyUrls');
    } catch (e) {
        return [];
    }
}
async function getCities() {
    try {
        return await get(NAMESPACE + '/getCities');
    } catch (e) {
        return [];
    }
}

async function createFriendlyUrl(friendlyUrl) {
    return await post(NAMESPACE + '/createFriendlyUrl', friendlyUrl);
}

async function editFriendlyUrl(friendlyUrl) {
    return await post(NAMESPACE + '/editFriendlyUrl', friendlyUrl);
}

async function deleteFriendlyUrl(friendly) {
    return await post(NAMESPACE + '/deleteFriendlyUrl', {friendly});
}

async function editCityLinks(city_id, links) {
    return await post(NAMESPACE + '/editCityLinks', {city_id, links});
}

async function getConfigValues() {
    return await get('/data/config');
}

async function updateCityConfig(city, config) {
    return await post(`${NAMESPACE}/cityConfig/${city}/update`, config);
}

async function createCityConfig(config) {
    return await post(`${NAMESPACE}/cityConfig/create`, config);
}

export const seoService = {
    getFriendlyUrls,
    getCities,
    createFriendlyUrl,
    editFriendlyUrl,
    deleteFriendlyUrl,
    editCityLinks,
    getConfigValues,
    updateCityConfig,
    createCityConfig
}
