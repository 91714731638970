import React from 'react'

const Spinner = ({inline, small}) => {

    return (
        <div className={(inline?"spinner-wrapper ":'')+(small?"spinner-wrapper-small":'')}>
            <div className={"spinner-container" + (inline?' spinner-inline':'') + (small?' spinner-small':'')}>
                <div className="donut"/>
            </div>
        </div>
    )
}

export default Spinner
