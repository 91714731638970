import React from "react";
import {useTranslation} from "react-i18next";
import VisitDay from "./VisitDay";
import Select from "react-select";
import {toCapitalize} from "../shared/utils";

const VisitsSection = ({property, handleChange}) => {

    const {accept_visitors, accept_visit_type, languages} = property;


    let visitWeekDaysOptions = [{option: 'sunday', label: "Sunday"},
        {option: 'monday', label: 'Monday'},
        {option: 'tuesday', label: 'Tuesday'},
        {option: 'wednesday', label: 'Wednesday'},
        {option: 'thursday', label: 'Thursday'},
        {option: 'friday', label: 'Friday'},
        {option: 'saturday', label: 'Saturday'}]


    let languagesNames = [{value:'portuguese', label:'Portuguese'},
        {value:'english', label:'English'},{value:'spanish', label:'Spanish'},
        {value:'italian', label:'Italian'},{value:'german', label:'German'},
        {value:'french', label:'French'}]

    const getPropertyLanguages = () => {
        let languagesList = [];
        for (let index in languages) {
            languagesList.push({value: languages[index], label: toCapitalize(languages[index])})
        }
        return languagesList;
    }

    const getVisitWeekdays = () => {
        if(!property.visit_weekdays){
            property.visit_weekdays = {};
            handleChange('visit_weekdays', property.visit_weekdays)
        }
        return property.visit_weekdays;
    }


    return(
        <div className={"PropertyEdit_column"}>
            <label className={"PropertyEdit_column-label"}>Visits</label>
            <div className={"PropertyEdit_row-inputs"}>
                <label className={"PropertyEdit_input-label mt-2 mr-1"}>Visit the property before the reservation exists?</label>
                <div className="form-check">
                    <label className="form-check-label">
                        <input type="checkbox" defaultChecked={accept_visitors} className="form-check-input"
                               onChange={() => {
                                   handleChange('accept_visitors', !accept_visitors)
                               }}/>
                        <i className="input-helper"/>
                        Yes
                    </label>
                </div>
            </div>
            {accept_visitors &&
            <div>
                <label className={"PropertyEdit_input-label mt-2 mr-1"}>Type of visits allowed:</label>
                <div className="form-check">
                    <label className="form-check-label">
                        <input type="radio" className="form-check-input" name="accept_visit_type" id="accept_visit_type"
                               defaultChecked={accept_visit_type !== 'onsite'} value={'livecall'}
                               onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                        Only by video call
                        <i className="input-helper"/>
                    </label>
                </div>
                <div className="form-check">
                    <label className="form-check-label">
                        <input type="radio" className="form-check-input" name="accept_visit_type"
                               id="accept_visit_type2" defaultChecked={accept_visit_type === 'onsite'} value={'onsite'}
                               onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                        In person and by video call
                        <i className="input-helper"/>
                    </label>
                </div>
            </div>}
            {accept_visitors &&
            <div>
                <label className={"PropertyEdit_input-label mt-2 mr-1"}>Choose the days and times to receive visit requests:</label>
                <div className="VisitWeekDays" id={'visit_weekdays'}>
                    {visitWeekDaysOptions.map((weekday, index) => (
                        <VisitDay property={property} weekday={weekday} weekdays={getVisitWeekdays()} key={weekday.label} handleChange={handleChange}/>
                    ))}
                </div>
            </div>}
            {accept_visitors &&
            <div>
                <label className={"PropertyEdit_input-label mt-2 mr-1"}>Languages in which you are comfortable communicating:</label>
                <Select
                    isMulti={true}
                    options={languagesNames}
                    defaultValue = {getPropertyLanguages()}
                    onChange={(options => {
                        let list = [];
                        Object.keys(options || []).map((lang)=> list.push(options[lang].value));
                        handleChange('languages', list)
                    })}
                />
            </div>}

        </div>
    )
}

export default VisitsSection;