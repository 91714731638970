import {get, patch, post} from "./index.js";

async function elasticFix(index, id, remove, force) {
    try {
        return await post('/backoffice/advanced/elasticFix', {index, id, remove, force});
    } catch (e) {
        return e;
    }
}

export const advancedService = {
    elasticFix
}
