import {get, patch, post} from "./index.js";
import moment from "moment";

const NAMESPACE = "/backoffice/tenant"

const getAge = (birthdate) => {
    return `${(moment().year() - moment(birthdate).year())}`;
}

const list = async (paginationKey, searchKey, filters) => {
    return await get(NAMESPACE + "/list", {paginationKey, searchKey, filters})
}

const getTenantById = async (tenant_id) => {
    return await get(`${NAMESPACE}/${tenant_id}`);
}

const getTenantReservations= async (tenant_id) => {
    return await get(`${NAMESPACE}/${tenant_id}/reservations`);
}

const getTenantVisits= async (tenant_id) => {
    return await get(`${NAMESPACE}/${tenant_id}/visits`);
}

const updateUser = async (toSave) => {
    return await post(`${NAMESPACE}/updateUser`, toSave)
}

const updateNotes = async (tenant_id, notes) => {
    return await post(`${NAMESPACE}/updateNotes`, {tenant_id, notes})
}

const recommendAds = async(userId, recommendations) => {
    return await post(`${NAMESPACE}/recommendAds`, {user_id: userId, recommended: recommendations})
}

const deleteUser = async (tenant_id) => {
    return await post(`${NAMESPACE}/deleteUser`, {tenant_id})
}

const unDeleteUser = async (tenant_id) => {
    return await post(`${NAMESPACE}/unDeleteUser`, {tenant_id})
}

const archiveUser = async (tenant_id) => {
    return await post(`${NAMESPACE}/archiveUser`, {tenant_id})
}

const unarchiveUser = async (tenant_id) => {
    return await post(`${NAMESPACE}/unarchiveUser`, {tenant_id})
}

const validateVideo = async (tenant_id, tenant_video, show) => {
    return await post(`${NAMESPACE}/validateVideo`, {tenant_id, tenant_video, show})
}


export const tenantService = {
    list,
    getTenantById,
    getAge,
    getTenantReservations,
    getTenantVisits,
    updateUser,
    recommendAds,
    deleteUser,
    unDeleteUser,
    archiveUser,
    unarchiveUser,
    updateNotes,
    validateVideo
}