import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import cogoToast from "cogo-toast";
import {useTranslation} from "react-i18next";

const ModalWrapper = ({title, children, onSubmit, show, onClose, size}) => {
    const[duringAction, setDuringAction] = useState(false);
    let errors = {"already_accepted": "already accepted", "invalid_internal_name": "invalid internal name"}

    const actionWrapper = (action) => async () => {
        if (duringAction) return;
        setDuringAction(true);
        try {
            await action();
        } catch (err) {
            console.error(err);
            if(err.validationErrors) {
                err.validationErrors.forEach(e => {
                    cogoToast.error(errors[e.msg] || " ", {
                        hideAfter: 6,
                        position: 'top-center',
                        heading: 'Error',
                        onClick: () => document.getElementById(e.param).scrollIntoView({block: "center", behavior: "smooth"})
                    })
                })
            } else {
                cogoToast.error(errors[(err.error?.message||err.error||err)] || " ", {
                    hideAfter: 6,
                    position: 'top-center',
                    heading: 'Error'
                })
            }
        }
        setDuringAction(false);
    }

    return (<Modal
        size={size}
        show={show}
        onHide={onClose}
        aria-labelledby="modal-dialog-wrapper"
    >
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            {children}
        </Modal.Body>

        <Modal.Footer>
            <Button variant="light m-2" onClick={onClose}>Cancel</Button>
            {onSubmit && <Button variant="success m-2" onClick={actionWrapper(onSubmit)} disabled={duringAction}>Confirm</Button>}
        </Modal.Footer>
    </Modal>)
}

export default ModalWrapper;
