import React, {useEffect, useState} from "react";
import KitchenForm from "./KitchenForm";
import BathRoomForm from "./BathRoomForm";
import LivingRoomForm from "./LivingRoomForm";




const PropertyAreas = ({property, handleChange}) => {

    const {
        numBalcony,
        numBathroom,
        numGarage,
        numKitchen,
        numLivingRoom,
        numPool,
        numRooftop,
        numTerrace,
        numYard,
        numGym
    } = property;

    let sectionDetails = property.sectionDetails || {};


    const [loading, setLoading] = useState(true)


    useEffect(()=>{
        let changed = false;
        let areas = ['numKitchen', 'numLivingRoom', 'numBathroom', 'numBalcony', 'numGarage', 'numPool', 'numRooftop', 'numTerrace', 'numYard', 'numGym'];
        areas.forEach((area)=>{
            if(isNaN(property[area])) handleChange(area, 0)
        })
        if(!sectionDetails) {
            sectionDetails={};
        }

        for(let i = 0 ; i<numKitchen; i++){
            if(!sectionDetails['kitchen_'+(i)]) {
                sectionDetails['kitchen_'+(i)]={}
                changed = true;
            }
        }
        for(let i = 0 ; i<numBathroom; i++){
            if(!sectionDetails['bathroom_'+(i)]) {
                sectionDetails['bathroom_'+(i)]={}
                changed = true;
            }

        }
        for(let i = 0 ; i<numLivingRoom; i++){
            if(!sectionDetails['livingRoom_'+(i)]) {
                sectionDetails['livingRoom_'+(i)]={}
                changed = true;
            }

        }

        let numKitchens = Object.keys(sectionDetails).filter(s=> s.startsWith("kitchen")).length;
        if(numKitchens !== numKitchen) handleChange('numKitchen', numKitchens);
        let numBathrooms = Object.keys(sectionDetails).filter(s=> s.startsWith("bathroom")).length;
        if(numBathrooms !== numBathroom) handleChange('numBathroom', numBathrooms);
        let numLivingRooms = Object.keys(sectionDetails).filter(s=> s.startsWith("livingRoom")).length;
        if(numLivingRooms !== numLivingRoom) handleChange('numLivingRoom', numLivingRooms);
        setLoading(false)
        if(changed) handleChange('sectionDetails', sectionDetails);
    }, [])



    const handleNumAreasChange = (type, num) => {
        let diff = 0;
        let keyName;
        switch (type){
            case 'numKitchen':
                keyName = "kitchen";
                diff = numKitchen - num;
                break;
            case 'numLivingRoom':
                keyName = "livingRoom";
                diff = numLivingRoom - num;
                break;
            case 'numBathroom':
                keyName = "bathroom";
                diff = numBathroom - num;
                break;
            default:
                handleChange(type, num)
                return;
        }
        if(diff > 0 ){
            let num = 0;
            let length = Object.keys(sectionDetails).filter(s=>s.startsWith(keyName)).length;
            while(num < diff){
                delete sectionDetails[keyName+"_"+(length - num -1)];
                num++;
            }
        }
        if(diff < 0){
            let num = 0
            let length = Object.keys(sectionDetails).filter(s=>s.startsWith(keyName)).length;
            while(num < -diff){
                sectionDetails[keyName+"_"+(length + num)] = {};
                num++;
            }
        }
        handleChange(type, num);
        handleChange('sectionDetails', sectionDetails)
    }



    const handleDivisionChange  = (area, field, value) => {
        field === 'size' ? (sectionDetails[area][field] =  (value) ) : (sectionDetails[area][field] =  (value === "true"));
        handleChange('sectionDetails', sectionDetails)
    }



    return (
        <>
            {!loading &&
            <div className={"PropertyEdit_column"} onBlur={(e) => handleNumAreasChange(e.target.name, e.target.valueAsNumber || 0)}>
                <label className={"PropertyEdit_column-label"}>Areas</label>
                <div className={"d-flex flex-row flex-wrap"}>
                    <div className={"d-flex flex-column mr-2"}>
                        <form>
                            <label className={"PropertyEdit_input-label"}>Kitchen</label>
                            <input type="number" min={0} className="form-control input-size-75" name="numKitchen"
                                   id="numKitchen" defaultValue={numKitchen} placeholder="Number of kitchens"/>
                        </form>
                        <form>
                            <label className={"PropertyEdit_input-label"}>Living rooms</label>
                            <input type="number" min={0} className="form-control input-size-75" name="numLivingRoom"
                                   id="numLivingRoom" defaultValue={numLivingRoom} placeholder="Number of living rooms"/>
                        </form>
                        <form>
                            <label className={"PropertyEdit_input-label"}>Balconies</label>
                            <input type="number" min={0} className="form-control input-size-75" name="numBalcony"
                                   id="numBalcony" defaultValue={numBalcony} placeholder="Number of balconies"/>
                        </form>
                        <form>
                            <label className={"PropertyEdit_input-label"}>Bathrooms</label>
                            <input type="number" min={0} className="form-control input-size-75" name="numBathroom"
                                   id="numBathroom" defaultValue={numBathroom} placeholder="Number of bathrooms"/>
                        </form>
                        <form>
                            <label className={"PropertyEdit_input-label"}>Garages</label>
                            <input type="number" min={0} className="form-control input-size-75" name="numGarage"
                                   id="numGarage" defaultValue={numGarage} placeholder="Number of garages"/>
                        </form>
                    </div>
                    <div className={"d-flex flex-column"}>
                        <form>
                            <label className={"PropertyEdit_input-label"}>Pool</label>
                            <input type="number" min={0} className="form-control input-size-75" name="numPool"
                                   id="numPool" defaultValue={numPool} placeholder="Number of pools"/>
                        </form>
                        <form>
                            <label className={"PropertyEdit_input-label"}>Rooftop</label>
                            <input type="number" min={0} className="form-control input-size-75" name="numRooftop"
                                   id="numRooftop" defaultValue={numRooftop} placeholder="Number of rooftops"/>
                        </form>
                        <form>
                            <label className={"PropertyEdit_input-label"}>Terraces/Patios</label>
                            <input type="number" min={0} className="form-control input-size-75" name="numTerrace"
                                   id="numTerrace" defaultValue={numTerrace} placeholder="Number of terraces/patios"/>
                        </form>
                        <form>
                            <label className={"PropertyEdit_input-label"}>Garden</label>
                            <input type="number" min={0} className="form-control input-size-75" name="numYard"
                                   id="numYard" defaultValue={numYard} placeholder="Number of gardens"/>
                        </form>
                        <form>
                            <label className={"PropertyEdit_input-label"}>GYM</label>
                            <input type="number" min={0} className="form-control input-size-75" name="numGym"
                                   id="numGym" defaultValue={numGym} placeholder="Number of gyms"/>
                        </form>
                    </div>
                </div>
            </div>}
            {!loading &&
            <div className={"PropertyEdit_column"}>
                {Object.keys(sectionDetails).filter(s=>s.startsWith("kitchen")).sort().map(s =>
                    <KitchenForm kitchen={sectionDetails[s]} numKitchens={numKitchen} index={parseInt(s?.split('_')[1])} key={s} handleChange={handleDivisionChange}/>
                )}
            </div>}
            {!loading &&
            <div className={"PropertyEdit_column"}>
                {Object.keys(sectionDetails).filter(s=>s.startsWith("bathroom")).map(s =>
                    <BathRoomForm bathroom={sectionDetails[s]} numBathrooms={numBathroom} index={parseInt(s?.split('_')[1])} key={s} handleChange={handleDivisionChange}/>)
                }
            </div>}
            {!loading &&
            <div className={"PropertyEdit_column"}>
                {Object.keys(sectionDetails).filter(s=>s.startsWith("livingRoom")).map(s =>
                    <LivingRoomForm livingRoom={sectionDetails[s]} numLivingRooms={numLivingRoom} index={parseInt(s?.split('_')[1])} key={s} handleChange={handleDivisionChange}/>)
                }
            </div>}
        </>
    )
};

export default PropertyAreas;