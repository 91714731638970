import React, {useEffect, useState} from 'react';
import {Form, FormGroup, OverlayTrigger, Tooltip} from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import moment from 'moment';
import Unavailability from "./Unavailability";
import {propertyService} from "../../services/propertyService";
import cogoToast from 'cogo-toast';
import {toCapitalize} from "../shared/utils";


const RoomForm = ({roomName, handleChange, property, changedFields, originalProperty}) => {
    let room = property.rooms[roomName];

    const {
        internalName,
        integration_id,
        fixedRent,
        rent,
        discount,
        extraTenantsPrice,
        availableFrom,
        rentMonths,
        confirmedAvailability,
        periodStayMax,
        periodStayMin,
        area,
        doubleBed,
        singleBed,
        bunkBed,
        numPeople,
        couplesAllowed,
        privateBathroom,
        kitchenette,
        window,
        internalWindow,
        externalWindow,
        balcony,
        adjectives,
        isFeaturedListing,
        isHotDeal,
        isBoosted
    } = room

    // const[photos, setPhotos] = useState(room.photos);
    let photos = room.photos || {}


    let firstAvailability = room.firstAvailability || propertyService.calcFirstAvailability(room);
    let unavailability = room.unavailability || []

    let months = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];

    let bathroomFeatures1 = ['toilet', 'sink', 'shower', 'bathtub'];
    let bathroomFeatures2 = ['mirror', 'dryer', 'wcWindow', 'wcHidro'];
    let kitchenetteOptions1 = ['fridge', 'freezer', 'stove', 'oven', 'potsPans', 'cutlery', 'coffeeMachine', 'toaster']
    let kitchenetteOptions2 = ['microwave', 'kettle', 'tables', 'chairs', 'juicer', 'blender', 'ventHood', 'kitchenWindow']
    let roomCommodities1 = ['lockKey', 'bedding', 'chair', 'desk', 'closet', 'shelf', 'hangers', 'drawers', 'sofa'];
    let roomCommodities2 = ['sofaBed', 'ac', 'fan', 'heating', 'electricHeating', 'tv', 'miniFridge', 'microwave'];
    let adjectivesNames = [
        {value: 'luminous', label: 'Luminous'},
        {value: 'comfortable', label: 'Comfortable'},
        {value: 'spacious', label: 'Spacious'},
        {value: 'modern', label: 'Modern'},
        {value: 'new', label: 'New'},
        {value: 'renovated', label: 'Renovated'},
        {value: 'cozy', label: 'Cozy'},
        {value: 'bright', label: 'Bright'}]


    const [imageToSave, setImageToSave] = useState();
    const exportURL = `${process.env.REACT_APP_API_BASE}/property/exportCalendar/${property.id}/${roomName}`;

    const copyUrl = async () => {
        await navigator.clipboard.writeText(exportURL);
        cogoToast.success('Successfully copied!', {position: 'top-center', hideAfter: 2});
    };

    const roomChangesHandler = (field, value) => {
        room[field] = value;
        let rooms = property.rooms;
        rooms[roomName][field] = value
        handleChange('rooms', rooms);
    }

    const getRoomAdjectives = () => {
        let roomAdj = [];
        for (let index in adjectives) {
            roomAdj.push({value: adjectives[index], label: toCapitalize(adjectives[index])})
        }
        return roomAdj;
    }

    const uploadRoomImage = async (image) => {
        let photosToSave = new FormData();
        let index = photos ? Object.keys(photos).length - 1 : 0; //featured nao conta
        photosToSave.append(roomName + "[" + index + "]", image)
        if (changedFields['rooms']?.hasOwnProperty(roomName) && changedFields['rooms'][roomName]['photos']) {
            let rooms = originalProperty['rooms']
            rooms[roomName]['photos'] = changedFields['rooms'][roomName]['photos'];
            await propertyService.editProperty({'id': changedFields['id'], 'rooms': rooms});
        }
        propertyService.uploadRoomPhotos(photosToSave, property.id).then(
            value => {
                let newPhotos = value.property.rooms[roomName].photos
                roomChangesHandler('photos', newPhotos)
            }
        )
        setImageToSave(null);
    };

    const deleteRoomImageHandler = (index) => {
        let length = Object.keys(photos).length
        let photo = photos[index]
        delete photos[index];
        if(isFeaturedPhoto(photo)) delete photos["featured"];
        let idx = parseInt(index)
        if(idx !== length - 2){
            for(let i=idx; i<length-2; i++){
                photos[i]=photos[i+1]
                delete photos[i+1]
            }
        }
        roomChangesHandler('photos', photos);
    }

    const highlightRoomImageHandler = (photo) => {
        photos['featured'] = photos[photo];
        roomChangesHandler('photos', photos);
    }

    const isFeaturedPhoto = (photo) => {
        if(!photos["featured"]) return false;
        return photos["featured"]["original"] === photo['original'];
    }

    const changeRents = (rent) =>{
        let rents = {};
        months.forEach(month => {
            rents[month] = rent;
        })
        return rents
    }

    useEffect(() => {
        if (!rentMonths && rent) {
            roomChangesHandler('rentMonths', changeRents(rent))
        }
    }, [])

    const photoPositionHandler = (index, mov) => {
        let photo = photos[index];
        let num = index + mov;
        photos[index] = photos[num];
        photos[num] = photo;
        roomChangesHandler('photos', photos)
    }

    const privateBathroomLabels = {
        "label": "Is there a private bathroom?",
        "desc": "Private Bathroom",
        "toilet": "Toilet",
        "sink": "Sink",
        "shower": "Shower",
        "bathtub": "Bathtub",
        "mirror": "Mirror",
        "dryer": "Hair dryer",
        "wcWindow": "Window to the outside",
        "wcHidro": "Whirlpool"
    }

    const kitchenetteLabels =  {
        "label": "Is there a private kitchenette?",
        "desc": "Private Kitchenette",
        "fridge": "Fridge",
        "freezer": "Freezer",
        "stove": "Stove",
        "oven": "Oven",
        "potsPans": "Pots and pans",
        "cutlery": "Plates and cutlery",
        "coffeeMachine": "Coffee machine",
        "toaster": "Toaster",
        "microwave": "Microwave",
        "kettle": "Kettle",
        "tables": "Tables",
        "chairs": "Chairs",
        "juicer": "Juicer",
        "blender": "Blender",
        "ventHood": "Vent hood",
        "kitchenWindow": "Window to the outside"
    }
    const roomCommodities =  {
        "lockKey": "Lock and Key",
        "bedding": "Bedding",
        "chair": "Chairs",
        "desk": "Secretary",
        "closet": "Closet / Wardrobe",
        "shelf": "Bookcase",
        "hangers": "Hangers",
        "drawers": "Drawers",
        "sofa": "Sofa",
        "sofaBed": "Sofa bed",
        "ac": "Air conditioner",
        "fan": "Fan",
        "heating": "Central heating",
        "electricHeating": "Electric heating",
        "tv": "TV",
        "miniFridge": "Mini Fridge",
        "microwave": "Microwave"
    }

    return (
        <>
            {/*Coluna 1*/}
            <div className={"PropertyEdit_column mr-2"}>
                <label className={"PropertyEdit_column-label"}>
                    {internalName}
                    <small> ({`${(property.accommodation === 'residence' && roomName?.split('_')[1] > property.bedroom_types) ? ("Studio" + ' ' + (roomName.split('_')[1] - property.bedroom_types)) : ("Room" + ' ' + roomName.split('_')[1])}`})</small>
                    <button className={"btn btn-primary ml-2"} onClick={() => propertyService.previewProperty(property, roomName)}>Preview</button>
                </label>
                <FormGroup>
                    <label className={"PropertyEdit_input-label"}>Integration ID</label>
                    <OverlayTrigger
                        overlay={<Tooltip className={`tooltip-blue`} id={`tooltip-blue`}>
                            Attention: Changing this attribute could cause <strong>duplicated</strong> rooms, please
                            double check.
                        </Tooltip>}>
                        <Form.Control type="text" className="form-control"  name="integration_id" id="integration_id"
                                      defaultValue={integration_id} placeholder="Integration ID"
                                      onChange={(e) => roomChangesHandler(e.target.name, e.target.value)}/>
                    </OverlayTrigger>
                </FormGroup>
                {((roomName === "room_1" && property.accommodation === 'apartment') || property.accommodation !== 'apartment') &&
                <>
                    <label className={"PropertyEdit_column-label-seconday"}>Prices and availability</label>
                    <div className={"PropertyEdit_row-inputs"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Is the rent amount fixed?</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" className="form-check-input"
                                       defaultChecked={fixedRent} value={!fixedRent} name={'fixedRent'}
                                       onChange={(e) => {
                                           roomChangesHandler(e.target.name, e.target.value === 'true')
                                           if(e.target.value === 'true') roomChangesHandler('rentMonths', changeRents(rent))
                                       }}
                                />
                                <i className="input-helper"/>
                                Yes
                            </label>
                        </div>
                    </div>
                    <div className={"PropertyEdit_row-inputs"}>
                        <FormGroup>
                            <label className={"PropertyEdit_input-label"}>Monthly rent amount</label>
                            <Form.Control type="number" className="form-control" min={0} name="rent"
                                          id="rent" defaultValue={rent}
                                          onChange={(e) => {
                                              roomChangesHandler(e.target.name, e.target.valueAsNumber || 0);
                                              roomChangesHandler('rentMonths', changeRents(e.target.valueAsNumber || 0));
                                          }}/>
                        </FormGroup>
                        <FormGroup>
                            <label className={"PropertyEdit_input-label"}>% discount for stays {">"} 5 months</label>
                            <Form.Control type="number" className="form-control" min={0} name="discount" id="discount"
                                          defaultValue={discount} placeholder="% discount"
                                          onChange={(e) => roomChangesHandler(e.target.name, e.target.valueAsNumber || 0)}/>
                        </FormGroup>
                    </div>
                    {!fixedRent && rentMonths &&
                    <div className={"Rent-months mb-3"}>
                        {Object.keys(rentMonths).map(month => {
                            return (
                                <div className={"rent"} key={roomName + "rent-" + month}>
                                    <label
                                        className={"PropertyEdit_input-label mr-2"}>{toCapitalize(month)}</label>
                                    <Form.Control type="number" className="form-control" min={0}
                                                  key={"input-"+roomName + "rent-" + month + "-" + rentMonths[month]}
                                                  id="rent" defaultValue={rentMonths[month]}
                                                  name={month} onChange={(e) => {
                                        rentMonths[e.target.name] = e.target.valueAsNumber || 0
                                        // roomChangesHandler('rentMonths', rentMonths)
                                    }}/>
                                </div>
                            )
                        })}
                    </div>}
                    <div className={"d-inline-flex flex-column mb-2"}>
                        <label className={"PropertyEdit_input-label mr-2"}>Extra monthly fee for each additional guest</label>
                        <input type={'number'} defaultValue={extraTenantsPrice !== 0 && extraTenantsPrice}
                               className="form-control"
                               placeholder={"Enter the extra amount per additional guest"}
                               name={"extraTenantsPrice"}
                               onChange={(e) => roomChangesHandler('extraTenantsPrice',e.target.valueAsNumber || 0)}/>
                    </div>
                    <div className={"mt-2"}>
                        <label className={"PropertyEdit_input-label mr-2"}>Available from:</label>
                        <DatePicker
                            selected={availableFrom ? new Date(availableFrom): new Date()}
                            onChange={(date) => {
                                roomChangesHandler('availableFrom', date.toISOString());
                            }}
                            disabled={room.openResidenceCalendar}
                            dateFormat={'dd/MM/yyyy'}
                            className="form-control"
                        />
                    </div>
                    {property.accommodation === 'residence' &&
                    <div className={"PropertyEdit_row-inputs"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Always keep calendar open</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" className="form-check-input"
                                       defaultChecked={room.openResidenceCalendar} value={!room.openResidenceCalendar}
                                       name={'openResidenceCalendar'}
                                       onChange={(e) => roomChangesHandler(e.target.name, e.target.value === 'true')}
                                />
                                <i className="input-helper"></i>
                                Yes
                            </label>
                        </div>
                    </div>
                    }
                    <div className={"PropertyEdit_row-inputs mt-2"}>
                        <FormGroup>
                            <label className={"PropertyEdit_input-label"}>Minimum stay (months):</label>
                            <Form.Control type="number" className="form-control" min={0} name="periodStayMin"
                                          id="periodStayMin"
                                          defaultValue={periodStayMin}
                                          onChange={(e) => roomChangesHandler(e.target.name, e.target.valueAsNumber || 0)}/>
                        </FormGroup>
                        <FormGroup>
                            <label className={"PropertyEdit_input-label"}>Maximum stay (months):</label>
                            <Form.Control type="number" className="form-control" min={0} name="periodStayMax"
                                          id="periodStayMax"
                                          defaultValue={periodStayMax}
                                          onChange={(e) => roomChangesHandler(e.target.name, e.target.valueAsNumber || 0)}/>
                        </FormGroup>
                    </div>
                    {!room.openResidenceCalendar && ((roomName === "room_1" && property.accommodation === 'apartment') || property.accommodation !== 'apartment') &&
                    <div className={"d-flex flex-column w-100"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Add unavailability periods</label>
                        {unavailability && unavailability.map((unavailabilityObject, index) => (
                            <Unavailability key={roomName+"-unavailability_"+index}
                                start={new Date(unavailabilityObject['start'])}
                                            end={new Date(unavailabilityObject['end'])}
                                            reason={unavailabilityObject['reason']}
                                            handleChange={roomChangesHandler} index={index} outages={unavailability}
                            />
                        ))}
                        <div className={"d-inline-flex mt-2"}>
                            <i className={"btn btn-secondary btn-rounded btn-icon mdi mdi-plus"} onClick={() =>
                            {
                                if(!unavailability){ let unavailability= [];}
                                unavailability.push({start: null, end: null, reason: null});
                                roomChangesHandler('unavailability', unavailability)
                            }}/>
                        </div>
                    </div>}
                    {!room.openResidenceCalendar &&
                    <div className={"d-flex flex-column w-100 mb-3"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Calendar synchronization</label>
                        <div className={"d-flex flex-column"}>
                            <label className={"checkbox-label"}>Export iCal URL</label>
                            <div className={"d-inline-flex flex-row"}>
                                <input className="form-control" value={exportURL} readOnly onClick={(e) => {
                                    e.target.focus();
                                    e.target.select();
                                }}/>
                                <button type={'button'} className={"btn btn-primary ml-2"}
                                        onClick={copyUrl}>Copy
                                </button>
                            </div>
                        </div>
                    </div>}
                    <div className={"fade alert alert-primary show mt-2"}>
                        This ad will be available for move-ins from {moment(new Date(firstAvailability)) < moment() ? moment().format('D MMM yyyy') : moment(firstAvailability).format('D MMM yyyy')}
                    </div>
                </>}
            </div>

            {/*Coluna 2*/}

            <div className={"PropertyEdit_column mr-2"}>
                <label className={"PropertyEdit_column-label-seconday"}>Details</label>
                <div className="form-check">
                    <label className="form-check-label text-muted">
                        <input type="checkbox" className="form-check-input" defaultChecked={isBoosted} onChange={() => roomChangesHandler('isBoosted', !isBoosted)} />
                        <i className="input-helper" />
                        Boost Listing
                    </label>
                </div>
                <div className="form-check">
                    <label className="form-check-label text-muted">
                        <input type="checkbox" className="form-check-input" defaultChecked={isFeaturedListing} onChange={() => roomChangesHandler('isFeaturedListing', !isFeaturedListing)} />
                        <i className="input-helper" />
                        Featured Listing
                    </label>
                </div>
                <div className="form-check">
                    <label className="form-check-label text-muted">
                        <input type="checkbox" className="form-check-input" defaultChecked={isHotDeal} onChange={() => roomChangesHandler('isHotDeal', !isHotDeal)} />
                        <i className="input-helper" />
                        Hot Deal
                    </label>
                </div>
                <FormGroup>
                    <label className={"PropertyEdit_input-label"}>Internal room name</label>
                    <Form.Control type="text" className="form-control" name="internalName" id="internalName"
                                  defaultValue={internalName} placeholder="Internal name"
                                  onChange={(e) => roomChangesHandler(e.target.name, e.target.value)}/>
                </FormGroup>
                <FormGroup>
                    <label className={"PropertyEdit_input-label"}>Room size (&#13217;)</label>
                    <Form.Control type="number" className="form-control" min={0} name="area" id="area"
                                  defaultValue={area}
                                  placeholder="Room size"
                                  onChange={(e) => roomChangesHandler(e.target.name, e.target.valueAsNumber || 0)}/>
                </FormGroup>
                <div className={"d-inline-flex"}>
                    <label className={"PropertyEdit_input-label mr-2"}>Double beds</label>
                    <input className="form-control" type={'number'} defaultValue={doubleBed}
                           onChange={(e) => roomChangesHandler('doubleBed', e.target.valueAsNumber || 0)}/>
                </div>
                <div className={"d-inline-flex"}>
                    <label className={"PropertyEdit_input-label mr-2"}>Single Beds</label>
                    <input className="form-control" type={'number'} defaultValue={singleBed}
                           onChange={(e) => roomChangesHandler('singleBed', e.target.valueAsNumber || 0)}/>
                </div>
                <div className={"d-inline-flex"}>
                    <label className={"PropertyEdit_input-label mr-2"}>Bunk beds</label>
                    <input className="form-control" type={'number'} defaultValue={bunkBed}
                           onChange={(e) => roomChangesHandler('bunkBed', e.target.valueAsNumber || 0)}/>
                </div>
                <div className={"d-inline-flex"}>
                    <label className={"PropertyEdit_input-label mr-2"}>Number of people</label>
                    <input className="form-control" type={'number'} defaultValue={numPeople}
                           onChange={(e) => roomChangesHandler('numPeople', e.target.valueAsNumber || 0)}/>
                </div>
                <div className={"PropertyEdit_row-inputs"}>
                    <label className={"PropertyEdit_input-label mt-2 mr-1"}>Are couples allowed?</label>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="checkbox" defaultChecked={couplesAllowed} className="form-check-input"
                                   onChange={() => {
                                       roomChangesHandler('couplesAllowed', !couplesAllowed)
                                   }}/>
                            <i className="input-helper"></i>
                            Yes
                        </label>
                    </div>
                </div>
                <div className={"PropertyEdit_row-inputs"}>
                    <label className={"PropertyEdit_input-label mt-2 mr-1"}>Does it have a private bathroom?</label>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="checkbox" defaultChecked={privateBathroom} className="form-check-input"
                                   onChange={() => {
                                       roomChangesHandler('privateBathroom', !privateBathroom)
                                   }}/>
                            <i className="input-helper"></i>
                            Yes
                        </label>
                    </div>
                </div>
                {privateBathroom &&
                <div className={'d-flex flex-row Area-features'}
                     onChange={(e) => roomChangesHandler(e.target.name, e.target.value)}>
                    <div className={'d-flex flex-column'}>
                        {bathroomFeatures1.map(value => {
                            return (
                                <div className={"d-inline-flex justify-content-between"} key={"bathroom-" + roomName + "" + value}>
                                    <label
                                        className={"checkbox-label mt-2 mr-1"}>{privateBathroomLabels[value]}</label>
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input type="checkbox" defaultChecked={room[value]}
                                                   className="form-check-input" name={value}
                                                   value={!room[value]}/>
                                            <i className="input-helper"></i>
                                        </label>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className={'d-flex flex-column'}>
                        {bathroomFeatures2.map(value => {
                            return (
                                <div className={"d-inline-flex justify-content-between"} key={"bathroom-" + roomName + "" + value}>
                                    <label
                                        className={"checkbox-label mt-2 mr-1"}>{privateBathroomLabels[value]}</label>
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input type="checkbox" defaultChecked={room[value]}
                                                   className="form-check-input" name={value} value={!room[value]}/>
                                            <i className="input-helper"></i>
                                        </label>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>}
                <div className={"PropertyEdit_row-inputs"}>
                    <label className={"PropertyEdit_input-label mt-2 mr-1"}>Does it have a private kitchenette?</label>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="checkbox" defaultChecked={kitchenette} className="form-check-input"
                                   onChange={() => {
                                       roomChangesHandler('kitchenette', !kitchenette)
                                   }}/>
                            <i className="input-helper"></i>
                            Yes
                        </label>
                    </div>
                </div>
                {kitchenette &&
                <div className={'d-flex flex-row Area-features'}
                     onChange={(e) => roomChangesHandler(e.target.name, e.target.value)}>
                    <div className={'d-flex flex-column'}>
                        {kitchenetteOptions1.map(value => {
                            return (
                                <div className={"d-inline-flex justify-content-between"} key={"kitchenette-" + value}>
                                    <label className={"checkbox-label mt-2 mr-1"}>{kitchenetteLabels[value]}</label>
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input type="checkbox" defaultChecked={room[value]}
                                                   className="form-check-input" name={value}
                                                   value={!room[value]}/>
                                            <i className="input-helper"></i>
                                        </label>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className={'d-flex flex-column'}>
                        {kitchenetteOptions2.map(value => {
                            return (
                                <div className={"d-inline-flex justify-content-between"} key={"kitchenette-" + value}>
                                    <label className={"checkbox-label mt-2 mr-1"}>{kitchenetteLabels[value]}</label>
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input type="checkbox" defaultChecked={room[value]}
                                                   className="form-check-input" name={value} value={!room[value]}/>
                                            <i className="input-helper"></i>
                                        </label>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>}
                <div className={"PropertyEdit_row-inputs"}>
                    <label className={"PropertyEdit_input-label mt-2 mr-1"}>Does it have a window?</label>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="checkbox" defaultChecked={window} className="form-check-input"
                                   onChange={() => {
                                       roomChangesHandler('window', !window)
                                   }}/>
                            <i className="input-helper"></i>
                            Yes
                        </label>
                    </div>
                </div>
                {window &&
                <div>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="window_type" id="window_type1"
                                   defaultChecked={internalWindow}
                                   onChange={() => {
                                       roomChangesHandler('internalWindow', true);
                                       roomChangesHandler('externalWindow', false);

                                   }}/>
                            For an indoor space
                            <i className="input-helper"></i>
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="window_type"
                                   id="window_type2" defaultChecked={externalWindow}
                                   onChange={() => {
                                       roomChangesHandler('internalWindow', false);
                                       roomChangesHandler('externalWindow', true);

                                   }}/>
                            To the exterior
                            <i className="input-helper"></i>
                        </label>
                    </div>
                </div>
                }
                <div className={"PropertyEdit_row-inputs"}>
                    <label className={"PropertyEdit_input-label mt-2 mr-1"}>Does it have a balcony?</label>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="checkbox" defaultChecked={balcony} className="form-check-input"
                                   onChange={() => {
                                       roomChangesHandler('balcony', !balcony)
                                   }}/>
                            <i className="input-helper"></i>
                            Yes
                        </label>
                    </div>
                </div>{/*
                <div className={"PropertyEdit_row-inputs flex-column"}>
                    <label className={"PropertyEdit_input-label mt-2 mr-1"} htmlFor={"notes"}>Other relevant information:</label>
                    <div className={"d-flex align-items-center"}>
                        <label htmlFor="notesPT" className={"mr-1"}>PT</label>
                        <textarea className="form-control" id="notesPT" name={"notesPT"} placeholder={"Notes PT"} rows="2" defaultValue={notes?.pt} onBlur={(e)=>roomChangesHandler('notes', {...notes, "pt": e.target.value})}/>
                    </div>
                    <div className={"d-flex align-items-center"}>
                        <label htmlFor="notesEN" className={"mr-1"}>EN</label>
                        <textarea className="form-control" id="notesEN" name={"notesEN"} placeholder={"Notes EN"} rows="2" defaultValue={notes?.en} onBlur={(e)=>roomChangesHandler('notes', {...notes, "en": e.target.value})}/>
                    </div>
                    <div className={"d-flex align-items-center"}>
                        <label htmlFor="notesES" className={"mr-1"}>ES</label>
                        <textarea className="form-control" id="notesES" name={"notesES"} placeholder={"Notes ES"} rows="2" defaultValue={notes?.es} onBlur={(e)=>roomChangesHandler('notes', {...notes, "es": e.target.value})}/>
                    </div>
                    <div className={"d-flex align-items-center"}>
                        <label htmlFor="notesIT" className={"mr-1"}>IT</label>
                        <textarea className="form-control" id="notesIT" name={"notesIT"} placeholder={"Notes IT"} rows="2" defaultValue={notes?.it} onBlur={(e)=>roomChangesHandler('notes', {...notes, "it": e.target.value})}/>
                    </div>
                </div>*/}
            </div>

            {/*Coluna 3*/}
            <div className={"PropertyEdit_column mr-2"}>
                <label className={"PropertyEdit_column-label-seconday"}>Amenities</label>
                <div className={'d-flex flex-row Area-features'}
                     onChange={(e) => roomChangesHandler(e.target.name, e.target.value)}>
                    <div className={'d-flex flex-column'}>
                        {roomCommodities1.map(value => {
                            return (
                                <div className={"d-inline-flex justify-content-between"} key={"room-comodity" + roomName + "" + value}>
                                    <label
                                        className={"checkbox-label mt-2 mr-1"}>{roomCommodities[value]}</label>
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input type="checkbox" defaultChecked={room[value]}
                                                   className="form-check-input" name={value}
                                                   value={!room[value]}/>
                                            <i className="input-helper"></i>
                                        </label>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className={'d-flex flex-column'}>
                        {roomCommodities2.map(value => {
                            return (
                                <div className={"d-inline-flex justify-content-between"} key={"room_commodity-" + roomName + "" + value}>
                                    <label
                                        className={"checkbox-label mt-2 mr-1"}>{roomCommodities[value]}</label>
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input type="checkbox" defaultChecked={room[value]}
                                                   className="form-check-input" name={value} value={!room[value]}/>
                                            <i className="input-helper"></i>
                                        </label>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className={"mb-3"}>
                    <label className={"PropertyEdit_input-label"}>Choose the adjectives that best characterize the room</label>
                    <Select
                        isMulti={true}
                        options={adjectivesNames}
                        defaultValue={getRoomAdjectives()}
                        onChange={(options => {
                            let list = [];
                            Object.keys(options || []).map((adj) => list.push(options[adj].value));
                            roomChangesHandler('adjectives', list)
                        })}
                    />
                </div>
                <div>
                    <label className={"PropertyEdit_column-label"}>Photographs</label>
                    <div>
                        <label className={"PropertyEdit_column-label-seconday"}>Featured Photo</label>
                        {photos?.["featured"] ?<div className="file-image">
                        <span
                            style={{backgroundImage: `url(${photos["featured"]['sm']})`}}/>
                        </div> :
                            <p>Choose an image to feature.</p>
                        }
                    </div>
                    <div className={"f-flex flex-column mb-1"}>
                        <label className={"PropertyEdit_column-label-seconday"}>Add photo</label>
                        <Form.Group>
                            <div className="custom-file">
                                <input type="file" accept={"image/*"} className="form-control visibility-hidden"
                                       id={"customFileLang" + roomName} lang="es"
                                       onChange={(e) => setImageToSave(e.target.files[0])}
                                />
                                <label style={{zIndex: 0}}
                                       className="custom-file-label"
                                       htmlFor={"customFileLang" + roomName}>{imageToSave?.name || "Choose the image"}</label>
                            </div>
                        </Form.Group>
                        <button type={'button'} className={"btn btn-primary btn-rounded"}
                                disabled={!imageToSave}
                                onClick={() => uploadRoomImage(imageToSave)}><i className="fa fa-upload mr-2"/>Upload
                        </button>
                    </div>
                    <div className={"d-flex flex-column"}>
                        {Object.keys(photos).length > 0 &&
                        <label className={"PropertyEdit_column-label-seconday"}>Remove photo</label>}
                        <div className={"display-photos"}>
                            {photos && Object.keys(photos).map(photo => {
                                if (photo !== 'featured') {
                                    return (
                                        <div key={roomName + "- photo -" + photo} className="file-image mb-2 mr-3" id={roomName + "-image-" + photos[photo]['sm']}>
                                            {Object.keys(photos).length > 2 && photo < Object.keys(photos).length - 2 &&
                                            <div className={"move-right image-action clickable"}
                                                 onClick={() => photoPositionHandler(parseInt(photo), 1)}>
                                                <i className={"mdi mdi-arrow-right"}/>
                                            </div>}
                                            {Object.keys(photos).length > 2 && photo > 0 &&
                                            <div className={"move-left image-action clickable"} onClick={() => photoPositionHandler(parseInt(photo), -1)}>
                                                <i className={"mdi mdi-arrow-left"}/>
                                            </div>}
                                            <div className={"delete-image image-action clickable"}
                                                 onClick={() => deleteRoomImageHandler(photo)}><OverlayTrigger
                                                overlay={<Tooltip>Delete</Tooltip>}><i
                                                className={"mdi mdi mdi-delete"}/></OverlayTrigger></div>
                                            <div className={"highlight-image image-action clickable"}
                                                 onClick={() => highlightRoomImageHandler(photo)}><OverlayTrigger
                                                overlay={<Tooltip>Highlight</Tooltip>}><i
                                                className={"mdi mdi-arrow-top-right"}/></OverlayTrigger></div>
                                            <span
                                                style={{backgroundImage: `url(${photos[photo]['sm']})`}}/>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RoomForm;
