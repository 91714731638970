import React, {useState, useEffect, useRef} from "react";
import {advancedService} from "../../services/advancedService.js";
import cogoToast from "cogo-toast";


const AdvancedPage = ({setModal}) => {

    const elasticFixRef = useRef({index: "", id: "", remove: false, force: false});

    const handleChangeElasticFix = (e) => {
        let val = e.target.value;
        let tname = e.target.name;
        if (tname === "remove" || tname === "force") {
            val = e.target.checked;
        }
        elasticFixRef.current[tname] = val;
    }

    const handleOpenElasticFix = async () => {
        elasticFixRef.current = {index: "", id: "", remove: false, force: false};
        setModal({
            title: "Elastic Fix",
            message: <div>
                <form>
                    <div className="form-group mb-2">
                        <label htmlFor="index">Index</label>
                        <input type="text" className="form-control" name="index" onChange={handleChangeElasticFix}/>
                        <br/>
                        <label htmlFor="id">ID</label>
                        <input type="text" className="form-control" name="id" onChange={handleChangeElasticFix}/>
                        <br/>
                        <label htmlFor="remove">Remove</label>
                        <input type="checkbox" className="form-control" name="remove" onChange={handleChangeElasticFix}/>
                        <br/>
                        <label htmlFor="force">Force</label>
                        <input type="checkbox" className="form-control" name="force" onChange={handleChangeElasticFix}/>
                    </div>
                </form>
            </div>,
            onSubmit: async () => {
                let res = await advancedService.elasticFix(elasticFixRef.current.index, elasticFixRef.current.id, elasticFixRef.current.remove, elasticFixRef.current.force);
                if (res.error) {
                    cogoToast.error("Error on elastic fix: " + res.error.message);
                } else {
                    cogoToast.success("Fixed " + elasticFixRef.current.index + " " + elasticFixRef.current.id);
                }
                setModal(m => ({...m, hide: true}));
            }
        })
    }

    return <div className={"AdvancedPage"}>
        <h2>Não mexer aqui a não ser que saibam o que estão a fazer</h2>
        <div>
            <button className={"btn btn-primary"} onClick={handleOpenElasticFix}>Fix Elastic</button>
        </div>
    </div>
}

export default AdvancedPage;
