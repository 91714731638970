import React from "react";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {propertyService} from "../../services/propertyService";
import {Dropdown, OverlayTrigger, Tooltip} from "react-bootstrap";
import {Link} from "react-router-dom";


const RoomResume = ({
                        room,
                        changePage,
                        roomName,
                        property,
                        type,
                        onDelete,
                        onEnable,
                        onDisable,
                        onConfirm,
                        preview,
                        ...props
                    }) => {

    const {t} = useTranslation();


    const clampText = (text, length) => {
        if (!text || text.length < length) return text;
        return text.substring(0, length).trim() + '...';
    };


    const verifyAttributeExistenceToString = (object, attribute, addToEndLabel) => {
        let endResult = ''

        if (Array.isArray(attribute)) {
            for (let i = 0; i < attribute.length; i++) {
                if (object?.[attribute[i]]) {
                    endResult += `${object[attribute[i]]}${i === attribute.length - 1 ? '' : ', '}`;
                }
            }
            if (endResult === '') return "To be defined";
            return endResult;
        } else {
            if (object?.[attribute]) {
                endResult = object[attribute];

                if (addToEndLabel) endResult = endResult + ' ' + addToEndLabel
            }
        }
        if (endResult === '') return "To be defined";
        return endResult;
    }

    let services = ['privateBathroom', 'balcony', 'tv', 'fridge', 'ac', 'microwave', 'stove', 'shower', 'sofa'];


    let firstAvailability = room?.firstAvailability || propertyService.calcFirstAvailability(room);

    const S3_URL = "https://inlife-new.s3.eu-west-1.amazonaws.com/houses/";

    let roomPhoto = room.photos?.featured?.md || (room.integration_id && room.photos?.[0]?.md) || (S3_URL + property.id + room.photos?.[0]);

    return (
        <div className="Card card Room-Card">
            <div style={{backgroundImage: `url('${roomPhoto}')`}} className={"Card_featured-photo"}>
                <div className={"bottom-image mb-1 ml-1"}>{room.id?.split("-")[0]}</div>
            </div>
            <div className="Card_content">

                <div className={'RoomResume__info'}>
                    <label>{clampText(verifyAttributeExistenceToString(room, 'internalName'), 51)}
                        <small> ({`${(property.accommodation === 'residence' && roomName?.split('_')[1] > property.bedroom_types) ? ('Studio ' + (roomName?.split('_')[1] - property.bedroom_types)) : ("Room " + roomName?.split('_')[1])}`})</small></label>
                    <div className={'RoomResume__info-titles'}>
                        <p className={'RoomResume__info-title'}>Up to {room.numPeople} {room.numPeople > 1 ? 'people' : 'person'}</p>
                        {room.area && <p className={'RoomResume__info-title'}>
                            Area: {room.area} &#13217;</p>}
                        <p className={'RoomResume__info-title'}>
                            {!room.title ? `${(room.adjectives?.length > 0 ? room.adjectives[0] : 'new')} room ${(property.neighborhood !== 'Other' && property.neighborhood) ? ('nearby ' + property.neighborhood) : ''}` : room.title?.['en']}
                        </p>
                    </div>
                </div>
                <div className={"Card_tenant"}>
                    <div className={"RoomResume__services"}>
                        <label>Services</label>
                        <div className={"RoomResume__services-list"}>
                            {Object.values(services).map((service) => {
                                    return <div
                                        key={roomName+"_"+service}
                                        className={`${room[service] ? 'service' : 'blank'} mr-2`}>{t('services.' + service)}</div>
                                }
                            )}
                            <div className={`${property['wifi'] ? 'service' : 'blank'} mr-2`}>Wi-fi</div>
                        </div>
                    </div>
                </div>
                <div className={"Card_info"}>
                    {((roomName === "room_1" && property.accommodation === 'apartment') || property.accommodation !== 'apartment') ? (
                        <div className={"RoomResume__price"}>
                            <p>{propertyService.getPricesInterval(room, property)}</p>
                            <p>{property.billsIncluded ? 'Expenses included' : 'Expenses not included'}</p>
                            {property.accommodation === 'residence' && room?.openResidenceCalendar ?
                                <p>Calendar always open</p> :
                                (firstAvailability ?
                                    <p>Available from {moment(new Date(firstAvailability)) < moment() ? moment().format('D MMM yyyy') : moment(firstAvailability).format('D MMM yyyy')}
                                    </p>
                                    : <p>To be defined</p>)}
                        </div>) : <div></div>}
                </div>
                <div className={"RoomResume_actions"}>
                    <div className={"buttons"}>
                        <OverlayTrigger overlay={<Tooltip>Edit Room</Tooltip>}>
                            <div className={"action-edit clickable mr-2"} style={{background: "white"}}>
                                <Link to={`/property/${property.id}?room_id=${room.id}`} style={{textDecoration: "none"}}>
                                    <span className={"fa fa-pencil"}/>
                                </Link>
                            </div>
                        </OverlayTrigger>
                        <OverlayTrigger overlay={<Tooltip>Preview</Tooltip>}>
                            <div className={`action-edit clickable mr-2 mb-1 ${property.disabled && 'clicks-disabled'}`} onClick={preview(property, roomName)}>
                                <span className={"fa fa-eye"}/>
                            </div>
                        </OverlayTrigger>
                        <OverlayTrigger overlay={<Tooltip>{room.disabled ? 'Enable' : 'Disable'}</Tooltip>}>
                            <div className={`action-edit ${property.disabled ? 'clicks-disabled' : (room.disabled ? 'activate' : 'inactivate')} clickable mb-1`}
                                 onClick={room.disabled ? onEnable(property, room.id) : onDisable(property, room.id)}>
                                <span className={`fa ${room.disabled ? 'fa-check-circle-o' : 'fa-ban'}`}/>
                            </div>
                        </OverlayTrigger>
                    </div>
                    <div className={"buttons"}>
                        <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                            <div className={"action-edit delete clickable mr-2"} onClick={onDelete(property, room.id)}>
                                <span className={"fa fa-times-circle"}/>
                            </div>
                        </OverlayTrigger>
                        <OverlayTrigger overlay={<Tooltip>Confirm availability</Tooltip>}>
                            <div
                                className={`action-edit confirm ${(!(property.accommodation === 'residence' && room?.openResidenceCalendar) && ((roomName === "room_1" && property.accommodation === 'apartment') || property.accommodation !== 'apartment') && !property.disabled) ? 'clickable' : 'clicks-disabled'}`}
                                onClick={onConfirm(property, room)}>
                                <span className={"fa fa-calendar-check-o"}/>
                            </div>
                        </OverlayTrigger>
                    </div>
                </div>
                <div className={"Card_extra"}>
                    <Dropdown drop={"start"}>
                        <Dropdown.Toggle variant="" id="dropdownMenuButton1"/>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={async ()=>{
                                await navigator.clipboard?.writeText(room.id);
                                console.log("room id copied to clipboard");
                            }}>Copy Room ID</Dropdown.Item>
                            <Dropdown.Item onClick={async ()=>{
                                await navigator.clipboard?.writeText(JSON.stringify(room));
                                console.log("room copied to clipboard");
                            }}>Copy Room JSON</Dropdown.Item>
                            <Dropdown.Item onClick={()=>{
                                window["room"] = room;
                                console.log("room stored as variable", '"room"');
                            }}>Store as variable</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </div>
    )
}

export default RoomResume;
