import {get, patch, post} from "./index.js";
import moment from "moment";

const NAMESPACE = "/backoffice/properties"

const list = async (paginationKey, searchKey, filters) => {
    return  await get(NAMESPACE + "/list", {paginationKey, searchKey, filters});
}

const listIntegrations = async (paginationKey, searchKey, filters) => {
    return  await get(NAMESPACE + "/listIntegrations", {paginationKey, searchKey, filters});
}

const getProperties = async (searchKey, filters, user_id) => {
    //return await get(`backoffice/landlord/efb0128a-e431-4055-9764-53bd6a57017c/properties`)
    let paginationKey = 0;
    return  await get(NAMESPACE + "/list", {searchKey, paginationKey, user_id});
}

const getPricesInterval = (room, property) => {
    let currentRoom = room;
    if (currentRoom?.rentMonths && Object.keys(currentRoom?.rentMonths)?.length > 0) {
        let minRent = currentRoom.rentMonths['january'];
        let maxRent = currentRoom.rentMonths['january'];
        Object.keys(currentRoom.rentMonths).map(month => {
            if (currentRoom.rentMonths[month] < minRent) minRent = currentRoom.rentMonths[month]
            if (currentRoom.rentMonths[month] > maxRent) maxRent = currentRoom.rentMonths[month]
        })
        if (minRent === maxRent) return minRent + '€';
        return minRent + ' - ' + maxRent + '€';
    } else if (currentRoom?.fixedRent || (property.migrated && currentRoom?.rent)) {
        return currentRoom.rent + '€';
    } else {
        return ('tbd');
    }
};

const getRentsInterval = (property) => {
    let min;
    let max;
    let minRoomRent;
    let maxRoomRent;
    let rooms = Object.values(property.rooms);
    rooms.forEach(room => {
        if(room.minRent && room.maxRent) {
            if (!max || room.maxRent > max) max = room.maxRent;
            if (!min || room.minRent < min) min = room.minRent;
        } else {
            let roomRents = getPricesInterval(rooms[room], property)?.split('-');
            if (roomRents.length > 1) {
                minRoomRent = parseInt(roomRents[0].trim());
                maxRoomRent = parseInt(roomRents[1].replace(/\D/g, "").trim())
            } else {
                minRoomRent = maxRoomRent = parseInt(roomRents[0].replace(/\D/g, "").trim());
            }
            if (!max || maxRoomRent > max) max = maxRoomRent;
            if (!min || minRoomRent < min) min = minRoomRent;
        }
    });

    if(!min && !max) return "";
    if (min === max) return min + '€';
    return min + ' - ' + max + '€';
}

async function disableProperty(propertyId) {
    return await post(`${NAMESPACE}/disable/${propertyId}`, {})
}

async function enableProperty(propertyId) {
    return await post(`${NAMESPACE}/enable/${propertyId}`, {})
}

async function deleteProperty(propertyId) {
    return await post(`${NAMESPACE}/delete/${propertyId}`, {})
}

const previewProperty = (property, roomName) => {
    let room = property.rooms[roomName];
    let house_path = room.url_slugs ? room.url_slugs["en"] : (property.id + (property.accommodation === "apartment" ? '' : ("/" + room.id)));
    window.open(process.env.REACT_APP_STUDENT_URL + "/house/" + house_path, '_blank');
}

const previewIntegration = (integration) => {
    let house_path = integration.url_slugs ? integration.url_slugs["en"] : (integration.id + (integration.room_id ? ("/" + integration.room_id) : ''));
    window.open(process.env.REACT_APP_STUDENT_URL + "/house/" + house_path, '_blank');
}

async function disableRoom(propertyId, roomId) {
    return await post(`${NAMESPACE}/disable/${propertyId}/room_${roomId}`, {})
}

async function enableRoom(propertyId, roomId) {
    return await post(`${NAMESPACE}/enable/${propertyId}/room_${roomId}`, {})
}

async function disableIntegration(propertyId, roomId) {
    return await post(`${NAMESPACE}/disableIntegration/${propertyId}/${roomId}`, {})
}

async function enableIntegration(propertyId, roomId) {
    return await post(`${NAMESPACE}/enableIntegration/${propertyId}/${roomId}`, {})
}

async function deleteRoom(propertyId, roomId) {
    return await post(`${NAMESPACE}/deleteRoom/${propertyId}/room_${roomId}`,{})
}

async function confirmAvailability(propertyId, roomId) {
    let url = `${NAMESPACE}/confirmAvailability/${propertyId}`;
    if (roomId) url += ("/room_" + roomId);
    return await post(url)
}

const calcFirstAvailability = (room) => {
    let firstAvailability = moment(room.availableFrom);
    if (firstAvailability.isBefore(moment()) || !firstAvailability.isValid()) firstAvailability = moment();
    [...(room.unavailability || []), ...(room.importedUnavailability || [])]
        .filter(unavailability => {
            if (!unavailability || unavailability.removed) return false;
            let momentEnd = moment(unavailability.end);
            return momentEnd.isAfter(firstAvailability) || momentEnd.isSame(firstAvailability);
        }).sort((u1, u2) => {
        let d1 = new Date(u1.end), d2 = new Date(u2.end);
        return d1 > d2 ? 1 : d1 < d2 ? -1 : 0;
    }).forEach(unavailability => {
        let startMoment = moment(unavailability.start);
        let endMoment = moment(unavailability.end);
        if ((firstAvailability.isAfter(startMoment) || firstAvailability.isSame(startMoment)) &&
            (firstAvailability.isBefore(endMoment) || firstAvailability.isSame(endMoment))) {
            firstAvailability = endMoment.clone().add(1, "day");
        }
    });
    return firstAvailability.toDate();
};

const getPropertyNumRooms = (property) => {
    if(property.accommodation === 'residence' ) return (property.bedrooms + property.studio_rooms) || 0;
    return property.rooms ? Object.keys(property.rooms).length : 0;
}

const validateProperty =  async (propertyId) => {
    return await post(`${NAMESPACE}/validate/${propertyId}`);
}

const getPropertyById = async (property_id) => {
    return await get(`/backoffice/property/${property_id}`);
}

async function uploadPropertyPhotos(photos,propertyId) {
    try {
        return await post(`/backoffice/upload/propertyPhotos/${propertyId}`, photos);
    } catch (e) {
        throw e;
    }
}
async function uploadRoomPhotos(photos,propertyId) {
    try {
        return await post(`/backoffice/upload/roomPhotos/${propertyId}`, photos);
    } catch (e) {
        throw e;
    }
}

const editProperty = async (changedFields) => {
    return await post(`/backoffice/property/edit`, changedFields)
}

async function addressCoords(address, region) {
    try {
        return await get(`/property/addressCoords`, {address, region});
    } catch (e) {
        throw e;
    }
}

async function saveResident(propertyId, roomId, resident) {
    return post(`/backoffice/saveResident/${propertyId}/${roomId}`, resident);
}

async function deleteResident(propertyId, roomId, residentId) {
    return post(`/backoffice/deleteResident/${propertyId}/${roomId}/${residentId}`);
}

async function updateListingParam(listing_id, params) {
    return post(`/backoffice/updateListingParam/${listing_id}`, params);
}

export const propertyService = {
    list,
    listIntegrations,
    getProperties,
    getPricesInterval,
    getRentsInterval,
    previewProperty,
    previewIntegration,
    disableProperty,
    enableProperty,
    enableRoom,
    disableRoom,
    disableIntegration,
    enableIntegration,
    deleteProperty,
    deleteRoom,
    calcFirstAvailability,
    confirmAvailability,
    getPropertyNumRooms,
    validateProperty,
    getPropertyById,
    uploadPropertyPhotos,
    uploadRoomPhotos,
    editProperty,
    addressCoords,
    saveResident,
    deleteResident,
    updateListingParam
}
