import React, {useEffect, useState} from 'react';
import {operatorService} from "../../services/operatorsService";
import Select from "react-select";
import cogoToast from "cogo-toast";
import {useDialogContext} from "../App";
import defaultAvatar from "../../assets/images/avatar.png";


const Operators = () => {

    const {setModal} = useDialogContext();
    const [operators, setOperators] = useState([]);
    const [permissions, setPermissions] = useState();
    const [types, setTypes] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [editingOperator, setEditingOperator] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                setOperators((await operatorService.getOperators()));
                let _permissions = await operatorService.getPermissions();
                setPermissions(_permissions)
                setTypes(Object.keys(_permissions));
            } catch (e) {
                setError(e.message || "Something went wrong");
                console.log(e)
            }
            setLoading(false)
        })();
    }, [])


    const getTypeTranslation = (type) => {
        if (type === "superadmin") return "Super Admin";
        let splitIndex;
        for (let i = 0; i < type.length; i++) {
            let char = type.charAt(i);
            if (char === char.toUpperCase()) splitIndex = i;
        }
        if (splitIndex) {
            return type.charAt(0).toUpperCase() + type.substring(1, splitIndex) + " " + type.substring(splitIndex);
        } else {
            return type.charAt(0).toUpperCase() + type.substring(1);
        }
    }

    const handleOperatorChange = (name, value) => {
        if (!editingOperator || name === "photo") return;
        setEditingOperator(prev => ({...prev, [name]: value}));
    }

    const handleSavePhoto = async (files, operator_id) => {
        let newPhoto = files?.[0];
        let formData = new FormData();
        formData.append("photo", newPhoto);
        formData.append("operator_id", operator_id);
        let response;
        try {
            if(newPhoto) response = await operatorService.editOperatorPhoto(formData);
            let newOperators = operators.map(o => {
                if (o.id === editingOperator.id) o = {...o, "photo": response.fname};
                return o;
            })
            setOperators(newOperators)
            cogoToast.success('Operator photo changed!', {
                hideAfter: 5,
                position: 'top-center',
                heading: 'Success'
            });
        } catch (e) {
            cogoToast.error("It was not possible to upload the photo", {
                hideAfter: 5,
                position: 'top-center',
                heading: e.error || "Error"
            });
        }
        setEditingOperator(null);
    }

    const handlePermissionsChange = async (typePermissions, type) => {
        typePermissions = typePermissions.map(p => p.value);
        let newPermissions = {...permissions, [type]: typePermissions}
        try {
            await operatorService.changePermissions(newPermissions);
            cogoToast.success('Permissions edited!', {
                hideAfter: 3,
                position: 'top-center',
                heading: 'Success'
            });
            setPermissions(newPermissions);
        } catch (e) {
            console.log(e)
            cogoToast.error("It was not possible to change permissions of " + type, {
                hideAfter: 4,
                position: 'top-center',
                heading: e.error || "Error"
            });
        }
    }

    const handleAddOperator = async () => {
        let newOperator = editingOperator;
        let response;
        try {
            response = await operatorService.createOperator(newOperator);
            let newOperators = operators.map(o => {
                if (o.id === newOperator.id) o = {...o, ...newOperator};
                o.id = response;
                return o;
            })
            setOperators(newOperators)
            setEditingOperator(null);
            cogoToast.success('Operator created!', {
                hideAfter: 3,
                position: 'top-center',
                heading: 'Success'
            });
        } catch (e) {
            console.log(e)
            cogoToast.error("It was not possible to create the operator", {
                hideAfter: 4,
                position: 'top-center',
                heading: e.error || "Error"
            });
        }
    }

    const handleSave = async () => {
        if(editingOperator.id === "newOperator") return handleAddOperator();
        try {
            setEditingOperator(null);
            let response = await operatorService.editOperator(editingOperator);
            if(response.error) return;
            let newOperators = operators.map(o => {
                if (o.id === editingOperator.id) o = {...o, ...editingOperator};
                return o;
            })
            setOperators(newOperators)
            cogoToast.info('Operator edited successfully!', {
                hideAfter: 5,
                position: 'top-center',
                heading: 'Success'
            });
        } catch (e) {
            console.log(e);
            let errorMessage = typeof e === "object" ? (e.message || e.error || 'Something went wrong!') : e;
            cogoToast.error(errorMessage, {
                hideAfter: 6,
                position: 'top-center',
                heading: "Error"
            });
        }
    }



    return (
        <div className={"Operators"}>
            <div className={"title mb-3"}><strong>Operators</strong></div>
            <div className={"OperatorsList"}>
                {operators.length && permissions ? operators.map(operator => {
                    let editing = editingOperator?.id === operator.id;
                    return <div className={"OperatorCard"} key={operator.id} id={operator.id} onChange={(e) => {
                        let name = e.target.name;
                        let value = name === "active" ? e.target.checked : e.target.value;
                        handleOperatorChange(name, value)
                    }}>
                        <div className={"mb-3"}>
                            <div className={"operator-photo"} style={{
                                backgroundImage: `url(${operator.photo || defaultAvatar})`,
                                cursor: (editing ? "pointer" : "")
                            }}>
                                {editing && <div className={'editIcon'}>
                                    <label htmlFor={operator.id + "-photo"}>
                                        <i className={"mdi mdi-lead-pencil"}/>
                                    </label>
                                    <input id={operator.id + "-photo"} className={"file-input"} type={"file"}
                                           name={"photo"} onChange={(e) => handleSavePhoto(e.target.files, operator.id)}/>
                                </div>}
                            </div>
                            {editing ? <input type={"text"} defaultValue={operator.name} name={"name"}/> :
                                <h3 className={"mb-2"}>{operator.name}</h3>}
                            {editing && <div className={"active-checkbox"}>
                                <label htmlFor={"active"}>Active</label>
                                <input type={"checkbox"} name={"active"} defaultChecked={operator.active} disabled={operator.id === "newOperator"}/>
                            </div>}
                        </div>
                        {editing ?
                            <input type={"text"} placeholder={"Username"} className={"mb-1"} name={"username"} defaultValue={operator.username}/> :
                            <span className={"mb-1"}><i
                                className="mdi mdi mdi-account text-primary mr-2"/>{operator.username}</span>}
                        {editing ?
                            <input type={"email"} placeholder={"Email"} className={"mb-1"} name={"email"} defaultValue={operator.email}/> :
                            <span className={"mb-1"}><i
                                className="mdi mdi-email-outline text-primary mr-2"/>{operator.email}</span>}
                        {editing ? <input type={"text"} className={"mb-1"} name={"whatsapp"} placeholder={"Whatsapp"}
                                          defaultValue={operator.whatsapp}/> : <span className={"mb-1"}><i
                            className="mdi mdi-cellphone text-primary mr-2"/>{operator.whatsapp || "N.D."}</span>}
                        {editing ? <textarea rows={"2"} className={"mb-1"} name={"summary"} placeholder={"Summary"}
                                             defaultValue={operator.summary}/> : <span className={"mb-1"}><i
                            className="mdi mdi-information text-primary mr-2"/>{operator.summary || "N.D."}</span>}
                        {editing &&
                        <input type={"text"} name={"password"} className={"mb-1"} placeholder={"New Password"}
                               minLength={5}/>}
                        {editing ? <Select className={"mb-2"} placeholder={"Type"} onChange={(o) => handleOperatorChange("type", o.value)}
                                           options={types.map(t => ({value: t, label: t}))}
                                           defaultValue={[{value: operator.type, label: operator.type}]}/> :
                            <span className={"mb-1"}><i
                                className="mdi mdi-account-box text-primary mr-2"/>{operator.type}</span>}
                        {(operator.extra_permissions?.length > 0 || editing) && <span>Extra Permissions:</span>}
                        {operator.extra_permissions?.length > 0 &&
                            <textarea className={"mb-2 permissions-textarea"} rows={"3"}
                                defaultValue={operator.extra_permissions?.join("; ")}/>}
                        {editing && <Select isMulti isClearable name={"extra_permissions"}
                                            className={"mb-2"}
                                            options={permissions["superadmin"].map(p => ({value: p, label:p}))}
                                            defaultValue={operator.extra_permissions?.map(p => ({value:p, label:p}))} placeholder={"Choose Extra Permissions"}
                                            onChange={(o) => handleOperatorChange("extra_permissions", o?.map(p => p.value) || [])}/>}
                        {editing ? <>
                                <button className={"btn btn-danger mb-1"} onClick={() => {
                                    if(editingOperator.id === "newOperator") setOperators(prevState => (prevState.filter(o => o.id !== "newOperator")))
                                    setEditingOperator(null)
                                }}>Cancel
                                </button>
                                <button className={"btn btn-primary"} onClick={handleSave}>Save</button>
                            </> :
                            <button className={"btn btn-primary"}
                                    onClick={() => {
                                        setOperators(prevState => (prevState.filter(o => o.id !== "newOperator")))
                                        setEditingOperator(operator)
                                    }}>Edit</button>}
                    </div>
                }) : (loading ? "Loading..." : error)}
                {!loading && !operators.some(o => o.id === "newOperator") && <div className={"OperatorCard AddOperator"} onClick={() => {
                    setOperators(prevState => ([...prevState, {"id": "newOperator", "active": true}]))
                    setEditingOperator({"id": "newOperator"});
                }}>
                    <i className={"mdi mdi-plus-circle"} />
                    <span>Create Operator</span>
                </div> }
            </div>
            {types &&
            <>
                <h2 className={"mb-3 mt-2"}>Profiles</h2>
                <div className={"Types"}>
                    {types.filter(type => type !== "superadmin").map(type => {
                        return <div id={type} className={"mb-1"}>
                            <label htmlFor={type}>{getTypeTranslation(type)}</label>
                            <Select closeMenuOnSelect={false} isMulti maxMenuHeight={"100px"}
                                    options={permissions["superadmin"]?.map(p => ({value: p, label: p}))} name={type}
                                    defaultValue={permissions[type]?.map(p => ({value: p, label: p}))}
                                    onChange={(o) => handlePermissionsChange(o, type)}
                            />
                        </div>
                    })}
                </div>
            </>}
        </div>
    )
}


export default Operators;