import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import defaultAvatar from "../../assets/images/avatar.png";
import RoomResume from "./RoomResume";
import {Link} from "react-router-dom";
import {propertyService} from "../../services/propertyService";
import {Dropdown, OverlayTrigger, Tooltip} from "react-bootstrap";
import moment from 'moment';

const IntegrationCard = ({
                          data: property,
                          disableHandler,
                          enableHandler,
                          handlePreview
                      }) => {

    const {t} = useTranslation();

    const [isHotDeal, setIsHotDeal] = useState(property.isHotDeal);
    const [isFeaturedListing, setIsFeaturedListing] = useState(property.isFeaturedListing);
    const [isBoosted, setIsBoosted] = useState(property.isBoosted);

    let landlordPhoto = property.landlord_photo || defaultAvatar;
    let propertyPhoto = [...(property.room_photos||[]), ...(property.photos||[])][0];
    let numRooms = 1;
    let numActive = 1;

    const clampText = (text, length) => {
        if (!text || text.length < length) return text;
        return text.substring(0, length).trim() + '...';
    };

    let isPropertyDisabled = () => {
        return property.disabled;
    }

    let propertyStatus = useMemo(() => {
        // bolinha verde se o anúncio estiver publicado,
        // bolinha amarela se estiver em progresso
        // bolinha vermelha se estiver inactivado
        if(!property) return;
        if(isPropertyDisabled()) return "disabled";
        if(property.finished) return "finished";

    }, [property])

    const handleChangeListingParam = (param) => async (e) => {
        property[param] = !property[param];
        let value = e.target.checked;
        let listing_id = property.id;
        if(property.room_id) listing_id += "_" + property.room_id;
        propertyService.updateListingParam(listing_id, {[param]: value}).then(() => {
            console.log("integration updated with", param, value);
        }).catch(console.error);
    }

    return (
        <>
            {
            <>
            <div className="Card card" style={{height: "180px"}}>
                <div style={{backgroundImage: `url('${propertyPhoto}')`}} className={"Card_featured-photo"}>
                    <div className={"top-left-image " + propertyStatus} />
                    <div className={"bottom-image mb-1 ml-1"}>{property.id?.split("-")[0]}</div>
                </div>
                <div className="Card_content">
                    <div className={"Card_property"}>
                        {property?.title &&
                        <label>{clampText(property?.title["en"], 35)}</label>}
                        {property.address && <div><i
                            className="mdi mdi-map-marker mr-2"/>{property.address} {property.internal_name &&
                        <small> ({property.internal_name})</small>}</div>}
                        {property.accommodation === 'apartment' && <div><i
                            className={"fa fa-users mr-2"}/>Up to {property.people} {property.people > 1 ? 'people' : 'person'}
                        </div>}
                        <div>
                            <i className="mdi mdi-home-city mr-2"/>
                            Integration ID: {property.integration_id}
                        </div>
                        <div>
                            <i className="mdi mdi-home-export-outline mr-2"/>
                            Platform: {property.integration_platform}
                        </div>
                        <div><i
                            className="mdi mdi-home mr-2"/>{t(`property_resume.accommodation_type.${property.accommodation}`)}
                        </div>
                        <div><i className="mdi mdi-coin mr-2"/>Monthly rent: {property.minRent === property.maxRent ? property.minRent : (`${property.minRent}-${property.maxRent}`)}€</div>
                    </div>
                    <div className={"Card_tenant"}>
                        <div className={"Card_tenant-image-wrapper"}>
                            <div style={{backgroundImage: `url('${landlordPhoto}')`}}
                                 className={"Card_tenant-image"}/>
                        </div>
                        <div className={"Card_tenant-info"}>
                            <label>{property.landlord_name} <Link to={"/landlords/" + property.landlord_id}>See Profile</Link></label>
                            <div className="mdi mdi-phone">{property.landlord_phone || "N/A"}</div>
                            <div className="mdi mdi-email-outline">{property.landlord_email || "N/A"}</div>
                            <div className={"mdi mdi-cash"}>{property.landlord_iban || "N/A"}</div>
                            <div className={"mdi mdi-file-document-edit-outline"}>{property.landlord_nif || "N/A"}</div>
                            <div>Commission {property.landlord_commission || "N/A"}</div>
                            <div>Accepted Ratio {property.landlord_acceptedRatio || "N/A"}</div>
                            <div>Accepted Reservations {property.landlord_acceptedReservations || "N/A"}</div>
                        </div>
                    </div>
                    <div className={"Card_info property-info"}>
                        <label>{property.accommodation === 'apartment' ? (numRooms || 1) : numRooms} {(numRooms > 1 ? "Rooms" : "Room") + " in "+ (property.accommodation === 'residence' ? 'residence' : 'property')}</label>
                        {property.instantBooking && <p>Instant Bookings</p>}
                        <p>Visit types: {property.accept_visit_type || "Not defined"}</p>
                        {property.lastElasticUpdate && <div>Updated {Math.abs(moment(property.lastElasticUpdate).diff(moment().startOf("day"), "days"))} days ago</div>}
                        {property.accommodation === 'apartment' ?
                            <p>1 Active listing</p> :
                            <p>{numActive} Active Listings</p>}
                        <div className="mdi mdi-calendar">{moment(property.firstAvailability).format("DD/MM/YYYY")}</div>
                    </div>
                    <div className={"Card_actions"}>
                        <div className={"Room_actions"}>
                            <div className={"property-actions mr-3"}>
                                <div className={"action-edit preview clickable mt-2"} onClick={handlePreview(property)}>
                                    <span className={"fa fa-eye mr-2 w-50"}/>
                                    <span>Preview</span>
                                </div>
                                <div
                                    className={`action-edit ${property.disabled ? 'activate' : 'inactivate'} clickable mt-2`}
                                    onClick={property.disabled ? enableHandler(property) : disableHandler(property)}>
                                        <span
                                            className={`fa ${property.disabled ? 'fa-check-circle-o' : 'fa-ban'} mr-2`}/>
                                    <span>{property.disabled ? 'Enable' : 'Disable'}</span>
                                </div>
                                <div className="form-check mb-0 mt-2">
                                    <label className="form-check-label text-muted">
                                        <input type="checkbox" className="form-check-input"
                                               onChange={(e) => {
                                                   handleChangeListingParam("isHotDeal")(e);
                                                   setIsHotDeal(!isHotDeal)
                                               }} checked={isHotDeal}/>
                                        <i className="input-helper"></i>
                                        Hot Deal
                                    </label>
                                </div>
                                <div className="form-check m-0">
                                    <label className="form-check-label text-muted">
                                        <input type="checkbox" className="form-check-input"
                                               onChange={(e) => {
                                                   handleChangeListingParam("isFeaturedListing")(e);
                                                   setIsFeaturedListing(!isFeaturedListing)
                                               }} checked={isFeaturedListing}/>
                                        <i className="input-helper"></i>
                                        Featured
                                    </label>
                                </div>
                                <div className="form-check m-0">
                                    <label className="form-check-label text-muted">
                                        <input type="checkbox" className="form-check-input"
                                               onChange={(e) => {
                                                   handleChangeListingParam("isBoosted")(e);
                                                   setIsBoosted(!isBoosted)
                                               }} checked={isBoosted}/>
                                        <i className="input-helper"></i>
                                        Boost
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"Card_extra"}>
                        <Dropdown drop={"start"}>
                            <Dropdown.Toggle variant="" id="dropdownMenuButton1"/>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={async ()=>{
                                    await navigator.clipboard?.writeText(property.id);
                                    console.log("property id copied to clipboard");
                                }}>Copy ID</Dropdown.Item>
                                <Dropdown.Item onClick={async ()=>{
                                    await navigator.clipboard?.writeText(JSON.stringify(property));
                                    console.log("property copied to clipboard");
                                }}>Copy JSON</Dropdown.Item>
                                <Dropdown.Item onClick={()=>{
                                    window["property"] = property;
                                    console.log("property stored as variable", '"property"');
                                }}>Store as variable</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
            </>}
        </>
    )
};

export default IntegrationCard;
