import React, {useState} from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';


const Clockpicker = ({startTime, timeChange, index, moment}) => {
    const [startDate, setStartDate] = useState(startTime || new Date());
    return (
        <DatePicker
            selected={startDate}
            onChange={(date) => {
                timeChange(date, moment, index);
                setStartDate(date);
            }}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={30}
            timeCaption="Time"
            dateFormat="HH:mm"
            timeFormat={"HH:mm"}
            className="form-control"
        />
    );
};

const VisitDay = ({weekday, weekdays, handleChange, property}) => {

    const{visit_weekdays} = property;

    const addTimeHandler = () => {
        let start = '9:00';
        let end = '20:00';
        if(!visit_weekdays.hasOwnProperty(weekday.option)){
            visit_weekdays[weekday.option] = {start : [], end: []};
        }
        visit_weekdays[weekday.option]['start'].push(start);
        visit_weekdays[weekday.option]['end'].push(end);
        handleChange('visit_weekdays', visit_weekdays);
    }

    const timeChangeHandler = (hour, momentOfDay, index) => {
        visit_weekdays[weekday.option][momentOfDay][index] = moment(hour).format("HH:mm");
        handleChange('visit_weekdays', visit_weekdays);
    }

    const deleteTimeHandler = (day, index) =>{
        visit_weekdays[weekday.option]['start'].splice(index, 1)
        visit_weekdays[weekday.option]['end'].splice(index, 1)
        if(visit_weekdays[weekday.option]['start'].length < 1){
            delete visit_weekdays[weekday.option];
        }
        handleChange('visit_weekdays', visit_weekdays);

    }

    return (
        <div className={"VisitDay"}>
            <div className={"VisitDay_button"}>
                <label>{weekday.label}</label>
                <div className={"btn btn-secondary btn-rounded btn-icon add-time-visit mb-2"} onClick={addTimeHandler
                }>
                    <i className={"mdi mdi-plus"}/>
                </div>
            </div>
            <div className={"VisitDay_hours"} key={weekday.option}>
                {weekdays[weekday.option] && weekdays[weekday.option].start.map((startDate, index) => {
                    return (
                        <div key={weekday.option+startDate+"_"+index}>
                            <Clockpicker
                                startTime={new Date().setHours(startDate?.split(':')[0], startDate?.split(':')[1])}
                                timeChange={timeChangeHandler}
                                index={index}
                                moment={'start'}
                            />
                            <Clockpicker
                                startTime={new Date().setHours(weekdays[weekday.option]?.end[index]?.split(':')[0], weekdays[weekday.option]?.end[index].split(':')[1])}
                                index={index}
                                moment={'end'}
                                timeChange={timeChangeHandler}
                            />
                            <div className={'close-button'} onClick={()=>deleteTimeHandler(weekday.option, index)}>
                                <i className={"clickable mdi mdi-close-box"}/>
                            </div>
                        </div>
                    )
                })}
            </div>

        </div>
    )
}

export default VisitDay;