import React, { useState} from 'react';
import { useHistory } from "react-router-dom";
import {useAuth} from "../../services/authService";
import cogoToast from 'cogo-toast';

const Login = () => {
    const {login} = useAuth();
    const history = useHistory();
    const [loggingIn, setLoggingIn] = useState(false);

    const handleLogin = async () => {
        if(loggingIn) return;
        setLoggingIn(true);
        let username = document.getElementById("username").value;
        let password = document.getElementById("password").value;
        try {
            await login(username, password);
            history.push("/dashboard");
        } catch (err) {
            cogoToast.error('Wrong username or password.',{
                hideAfter: 5,
                position: 'top-center',
                heading: 'Error'
            })
        } finally {
            setLoggingIn(false);
        }
    }

    return (
        <div className="d-flex align-items-stretch auth auth-img-bg h-100">
            <div className="row flex-grow">
                <div className="col-lg-6 d-flex align-items-center justify-content-center">
                    <div className="auth-form-transparent text-left p-3">
                        <div className="brand-logo">
                            <img src={require("../../assets/images/logo.svg")} alt="logo"/>
                        </div>
                        <h4>Backoffice Inlife</h4>
                        <form className="pt-3">
                            <div className="form-group">
                                <label>User</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text border-right-0">
                                          <i className="mdi mdi-account-outline text-primary"/>
                                        </span>
                                    </div>
                                    <input type="text" className="form-control form-control-lg border-left-0"
                                           id="username" placeholder="Username"/>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Password</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text border-right-0">
                                          <i className="mdi mdi-lock-outline text-primary"/>
                                        </span>
                                    </div>
                                    <input type="password" className="form-control form-control-lg border-left-0"
                                           id="password" placeholder="Password"/>
                                </div>
                            </div>
                            <div className="my-3">
                                <div className={"btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"+(loggingIn?' disabled':'')}
                                     onClick={handleLogin}>
                                    LOGIN
                                    {loggingIn && <i className="mdi mdi-loading mdi-spin"/>}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-lg-6 login-half-bg d-flex flex-row">
                </div>
            </div>
        </div>
    )
}

export default Login;
