import React, {useEffect, useRef, useState} from "react";
import PageWrapper from "../components/PageWrapper";
import {Tab, Tabs} from "react-bootstrap";
import {useDialogContext} from "../App.js";

import Payments from "./Payments";
import PromoCodes from "../promocodes/PromoCodes.js";
import Ambassadors from "../ambassadors/Ambassadors.js";


const Marketing = ({inline}) => {

    const {setModal} = useDialogContext();

    return (
        <PageWrapper title={"Marketing"}
                     breadcrumbs={[
                         {title: "Marketing"}
                     ]}
                     inline={inline} noCard>
            <Tabs defaultActiveKey="promocodes" id="uncontrolled-tab-example">
                <Tab eventKey={"promocodes"} title={"Promo Codes"}>
                    <PromoCodes/>
                </Tab>
                <Tab eventKey={"ambassadors"} title={"Ambassadors"}>
                    <Ambassadors/>
                </Tab>
                <Tab style={{marginTop: "25px"}} eventKey="payments" title="Payments">
                    <Payments/>
                </Tab>
            </Tabs>
        </PageWrapper>
    )
}

export default Marketing;
