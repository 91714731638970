import React, {Suspense, lazy} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import Spinner from '../app/shared/Spinner';
import Navbar from "./shared/Navbar";
import Sidebar from "./shared/Sidebar";
import Footer from "./shared/Footer";

import Login from "./user-pages/Login";
import Reservations from "./reservations/Reservations";
import Visits from "./visits/Visits";
import Tenants from "./tenants/Tenants";
import Landlords from "./landlords/Landlords";
import LandlordInformation from "./landlords/LandlordInformation";
//import LandlordEditListings from "./landlords/LandlordEditListings";
import Properties from "./properties/Properties";
import Integrations from "./integrations/Integrations.js";
import Invoices from "./invoices/Invoices";
import Finance from "./finance/Finance";
import Operators from "./operators/Operators";
import PropertyEdit from "./components/PropertyEdit";
import TenantDetails from "./tenants/TenantDetails";
import PromoCodes from "./promocodes/PromoCodes";
import Emails from "./emails/Emails";
import Config from "./config/Config.js";
import Ambassadors from "./ambassadors/Ambassadors";
import Marketing from "./marketing/Marketing";

const Dashboard = lazy(() => import('./dashboard/Dashboard'));
/*
const Widgets = lazy(() => import('./widgets/Widgets'));

const KanbanBoard = lazy(() => import('./apps/KanbanBoard'));
const Tickets = lazy(() => import('./apps/Tickets'));
const Chats = lazy(() => import('./apps/Chats'));
const TodoList = lazy(() => import('./apps/TodoList'));

const Accordions = lazy(() => import('./basic-ui/Accordions'));
const Buttons = lazy(() => import('./basic-ui/Buttons'));
const Badges = lazy(() => import('./basic-ui/Badges'));
const Breadcrumbs = lazy(() => import('./basic-ui/Breadcrumbs'));
const Dropdowns = lazy(() => import('./basic-ui/Dropdowns'));
const Modals = lazy(() => import('./basic-ui/Modals'));
const Progress = lazy(() => import('./basic-ui/Progress'));
const Paginations = lazy(() => import('./basic-ui/Paginations'));
const TabsPage = lazy(() => import('./basic-ui/TabsPage'));
const Typography = lazy(() => import('./basic-ui/Typography'));
const Tooltips = lazy(() => import('./basic-ui/Tooltips'));
const Popups = lazy(() => import('./basic-ui/Popups'));

const Dragula = lazy(() => import('./advanced-ui/Dragula'));
const Clipboard = lazy(() => import('./advanced-ui/Clipboards'));
const ContextMenu = lazy(() => import('./advanced-ui/ContextMenus'));
const Sliders = lazy(() => import('./advanced-ui/Sliders'));
const Carousel = lazy(() => import('./advanced-ui/Carousel'));
const Loaders = lazy(() => import('./advanced-ui/Loaders'));
const TreeView = lazy(() => import('./advanced-ui/TreeView'));

const BasicElements = lazy(() => import('./form-elements/BasicElements'));
const AdvancedElements = lazy(() => import('./form-elements/AdvancedElements'));
const Validation = lazy(() => import('./form-elements/Validation'));
const Wizard = lazy(() => import('./form-elements/Wizard'));

const BasicTable = lazy(() => import('./tables/BasicTable'));
const DataTable = lazy(() => import('./tables/DataTables'));
const ReactTable = lazy(() => import('./tables/ReactTable'));
const SortableTable = lazy(() => import('./tables/SortableTable'));

const VectorMap = lazy(() => import('./maps/VectorMap'));
const SimpleMap = lazy(() => import('./maps/SimpleMap'));

const Notifications = lazy(() => import('./notifications/Notifications'));

const Mdi = lazy(() => import('./icons/Mdi'));
const FlagIcons = lazy(() => import('./icons/FlagIcons'));
const FontAwesome = lazy(() => import('./icons/FontAwesome'));
const SimpleLine = lazy(() => import('./icons/SimpleLine'));
const Themify = lazy(() => import('./icons/Themify'));
const TypIcons = lazy(() => import('./icons/TypIcons'));

const TextEditors = lazy(() => import('./text-editors/TextEditors'));
const CodeEditor = lazy(() => import('./code-editor/CodeEditor'));

const ChartJs = lazy(() => import('./charts/ChartJs'));
const C3Charts = lazy(() => import('./charts/C3Charts'));
const Chartist = lazy(() => import('./charts/Chartist'));
const GoogleCharts = lazy(() => import('./charts/GoogleCharts'));
const SparkLineCharts = lazy(() => import('./charts/SparkLineCharts'));
const GuageChart = lazy(() => import('./charts/GuageChart'));

const Error404 = lazy(() => import('./error-pages/Error404'));
const Error500 = lazy(() => import('./error-pages/Error500'));

const Login = lazy(() => import('./user-pages/Login'));
const Login2 = lazy(() => import('./user-pages/Login2'));
const Register1 = lazy(() => import('./user-pages/Register'));
const Register2 = lazy(() => import('./user-pages/Register2'));
const Lockscreen = lazy(() => import('./user-pages/Lockscreen'));

const BlankPage = lazy(() => import('./general-pages/BlankPage'));
const Profile = lazy(() => import('./general-pages/Profile'));
const Faq = lazy(() => import('./general-pages/Faq'));
const Faq2 = lazy(() => import('./general-pages/Faq2'));
const NewsGrid = lazy(() => import('./general-pages/NewsGrid'));
const Timeline = lazy(() => import('./general-pages/Timeline'));
const SearchResults = lazy(() => import('./general-pages/SearchResults'));
const Portfolio = lazy(() => import('./general-pages/Portfolio'));
const UserListing = lazy(() => import('./general-pages/UserListing'));

const Invoice = lazy(() => import('./ecommerce/Invoice'));
const Pricing = lazy(() => import('./ecommerce/Pricing'));
const ProductCatalogue = lazy(() => import('./ecommerce/ProductCatalogue'));
const ProjectList = lazy(() => import('./ecommerce/ProjectList'));
const Orders = lazy(() => import('./ecommerce/Orders'));

const Email = lazy(() => import('./apps/Email'));
const Calendar = lazy(() => import('./apps/Calendar'));
const Gallery = lazy(() => import('./apps/Gallery'));
*/

const RouteWrapper = ({children, ...rest}) => {
    return (
        <div className="container-scroller">
            <Navbar/>
            <div className="container-fluid page-body-wrapper">
                <Sidebar/>
                <div className="main-panel">
                    <div className="content-wrapper">
                        <Route
                            {...rest}
                            render={({location}) => children}
                        />
                    </div>
                    <Footer/>
                </div>
            </div>
        </div>
    );
}

const AppRoutes = (props) => {
    return (
        <Suspense fallback={<Spinner/>}>
            <Switch>
                <RouteWrapper exact path="/dashboard" component={Dashboard}/>

                <RouteWrapper exact path="/operators" component={Operators}/>
                <RouteWrapper exact path="/reservations" component={Reservations}/>
                <RouteWrapper exact path="/visits" component={Visits}/>
                <RouteWrapper exact path="/tenants" component={Tenants}/>
                <RouteWrapper exact path="/tenants/:tenant_id" component={TenantDetails}/>
                <RouteWrapper exact path="/landlords" component={Landlords}/>
                <RouteWrapper exact path="/landlords/:landlord_id" component={LandlordInformation}/>
                {/*<RouteWrapper exact path="/landlords/:landlord_id/listings" component={LandlordEditListings}/>*/}
                <RouteWrapper exact path="/property/:property_id" component={PropertyEdit}/>
                <RouteWrapper exact path="/properties" component={Properties}/>
                <RouteWrapper exact path="/integrations" component={Integrations}/>
                <RouteWrapper exact path="/invoices" component={Invoices}/>
                <RouteWrapper exact path="/finance" component={Finance}/>
                <RouteWrapper exact path="/ambassadors" component={Ambassadors}/>
                <RouteWrapper exact path="/marketing" component={Marketing}/>
                <RouteWrapper exact path="/promocodes" component={PromoCodes}/>
                <RouteWrapper exact path="/emails" component={Emails}/>
                <RouteWrapper exact path="/config" component={Config}/>


                {/*
                <RouteWrapper exact path="/widgets" component={Widgets}/>

                <RouteWrapper exact path="/apps/kanban-board" component={KanbanBoard}/>
                <RouteWrapper exact path="/apps/todo-list" component={TodoList}/>
                <RouteWrapper exact path="/apps/tickets" component={Tickets}/>
                <RouteWrapper exact path="/apps/chats" component={Chats}/>

                <RouteWrapper path="/basic-ui/accordions" component={Accordions}/>
                <RouteWrapper path="/basic-ui/buttons" component={Buttons}/>
                <RouteWrapper path="/basic-ui/badges" component={Badges}/>
                <RouteWrapper path="/basic-ui/breadcrumbs" component={Breadcrumbs}/>
                <RouteWrapper path="/basic-ui/dropdowns" component={Dropdowns}/>
                <RouteWrapper path="/basic-ui/modals" component={Modals}/>
                <RouteWrapper path="/basic-ui/progressbar" component={Progress}/>
                <RouteWrapper path="/basic-ui/pagination" component={Paginations}/>
                <RouteWrapper path="/basic-ui/tabs" component={TabsPage}/>
                <RouteWrapper path="/basic-ui/typography" component={Typography}/>
                <RouteWrapper path="/basic-ui/tooltips" component={Tooltips}/>
                <RouteWrapper path="/basic-ui/popups" component={Popups}/>

                <RouteWrapper path="/advanced-ui/dragula" component={Dragula}/>
                <RouteWrapper path="/advanced-ui/clipboard" component={Clipboard}/>
                <RouteWrapper path="/advanced-ui/context-menu" component={ContextMenu}/>
                <RouteWrapper path="/advanced-ui/sliders" component={Sliders}/>
                <RouteWrapper path="/advanced-ui/carousel" component={Carousel}/>
                <RouteWrapper path="/advanced-ui/loaders" component={Loaders}/>
                <RouteWrapper path="/advanced-ui/tree-view" component={TreeView}/>

                <RouteWrapper path="/form-Elements/basic-elements" component={BasicElements}/>
                <RouteWrapper path="/form-Elements/advanced-elements" component={AdvancedElements}/>
                <RouteWrapper path="/form-Elements/validation" component={Validation}/>
                <RouteWrapper path="/form-Elements/wizard" component={Wizard}/>

                <RouteWrapper path="/tables/basic-table" component={BasicTable}/>
                <RouteWrapper path="/tables/data-table" component={DataTable}/>
                <RouteWrapper path="/tables/react-table" component={ReactTable}/>
                <RouteWrapper path="/tables/sortable-table" component={SortableTable}/>

                <RouteWrapper path="/maps/vector-map" component={VectorMap}/>
                <RouteWrapper path="/maps/simple-map" component={SimpleMap}/>

                <RouteWrapper path="/notifications" component={Notifications}/>

                <RouteWrapper path="/icons/mdi" component={Mdi}/>
                <RouteWrapper path="/icons/flag-icons" component={FlagIcons}/>
                <RouteWrapper path="/icons/font-awesome" component={FontAwesome}/>
                <RouteWrapper path="/icons/simple-line" component={SimpleLine}/>
                <RouteWrapper path="/icons/themify" component={Themify}/>
                <RouteWrapper path="/icons/typicons" component={TypIcons}/>

                <RouteWrapper path="/text-editors" component={TextEditors}/>
                <RouteWrapper path="/code-editor" component={CodeEditor}/>

                <RouteWrapper path="/icons/themify" component={Themify}/>

                <RouteWrapper path="/charts/chart-js" component={ChartJs}/>
                <RouteWrapper path="/charts/c3-chart" component={C3Charts}/>
                <RouteWrapper path="/charts/chartist" component={Chartist}/>
                <RouteWrapper path="/charts/google-charts" component={GoogleCharts}/>
                <RouteWrapper path="/charts/sparkline-charts" component={SparkLineCharts}/>
                <RouteWrapper path="/charts/guage-chart" component={GuageChart}/>


                <RouteWrapper path="/user-pages/login-1" component={Login}/>
                <RouteWrapper path="/user-pages/login-2" component={Login2}/>
                <RouteWrapper path="/user-pages/register-1" component={Register1}/>
                <RouteWrapper path="/user-pages/register-2" component={Register2}/>
                <RouteWrapper path="/user-pages/lockscreen" component={Lockscreen}/>

                <RouteWrapper path="/error-pages/error-404" component={Error404}/>
                <RouteWrapper path="/error-pages/error-500" component={Error500}/>

                <RouteWrapper path="/general-pages/blank-page" component={BlankPage}/>
                <RouteWrapper path="/general-pages/profile" component={Profile}/>
                <RouteWrapper path="/general-pages/faq-1" component={Faq}/>
                <RouteWrapper path="/general-pages/faq-2" component={Faq2}/>
                <RouteWrapper path="/general-pages/news-grid" component={NewsGrid}/>
                <RouteWrapper path="/general-pages/timeline" component={Timeline}/>
                <RouteWrapper path="/general-pages/search-results" component={SearchResults}/>
                <RouteWrapper path="/general-pages/portfolio" component={Portfolio}/>
                <RouteWrapper path="/general-pages/user-listing" component={UserListing}/>

                <RouteWrapper path="/ecommerce/invoice" component={Invoice}/>
                <RouteWrapper path="/ecommerce/pricing" component={Pricing}/>
                <RouteWrapper path="/ecommerce/product-catalogue" component={ProductCatalogue}/>
                <RouteWrapper path="/ecommerce/project-list" component={ProjectList}/>
                <RouteWrapper path="/ecommerce/orders" component={Orders}/>

                <RouteWrapper path="/apps/email" component={Email}/>
                <RouteWrapper path="/apps/calendar" component={Calendar}/>
                <RouteWrapper path="/apps/gallery" component={Gallery}/>
                */}

                <Route path="/login" component={Login}/>

                <Redirect to="/login"/>
            </Switch>
        </Suspense>
    );
}

export default AppRoutes;
