import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const RatingsOverlay = ({ ratings }) => {
  return ratings ? (
    <OverlayTrigger
      overlay={
        <Tooltip>
          Rating: {ratings.rating} <br />
          Ad Quality: {ratings.adQualityRating} <br />
          Average Reviews: {ratings.averageReviewsRating} <br />
          Cancelation: {ratings.cancelationRating} <br />
          Click Through: {ratings.clickThroughRating} <br />
          Confirmed Bookings: {ratings.confirmedBookingsRating} <br />
          Hot Deal: {ratings.hotDealRating} <br />
          Instant Booking: {ratings.instantBookingRating} <br />
          Just In New: {ratings.justInNewRating} <br />
          Landlord Acceptance: {ratings.landlordAcceptanceRating} <br />
          Landlord Average Time Of Response:{" "}
          {ratings.landlordAverageTimeOfResponseRating} <br />
          Landlord Cancelation: {ratings.landlordCancelationRating}{" "}
          <br />
          Landlord Responses: {ratings.landlordResponsesRating} <br />
          Last Update: {ratings.lastUpdateRating} <br />
          Photos Quality: {ratings.photosQualityRating} <br />
          Photos Quantity: {ratings.photosQuantityRating} <br />
          Positive Reviews: {ratings.positiveReviewsRating} <br />
          Price: {ratings.priceRating} <br />
          Top Landlord: {ratings.topLandlordRating}
        </Tooltip>
      }
    >
      <i className={`mdi mdi mdi-finance preview-action`} />
    </OverlayTrigger>
  ) : null;
};

export default RatingsOverlay;
