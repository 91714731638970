import React, {useState} from 'react';
import moment from 'moment';
import {useDialogContext} from "../App";
import {ambassadorService} from "../../services/ambassadorService";
import cogoToast from "cogo-toast";
import { Link } from 'react-router-dom';
import {useAuth} from "../../services/authService";
import Spinner from "../shared/Spinner";


const AmbassadorCard = ({data: userAmbassador}) => {

    let {user} = useAuth();
    const [ambassador, setAmbassador] = useState(userAmbassador.ambassador);
    const {setModal} = useDialogContext();
    const [loading, setLoading] = useState("");


    const handleEdit = () => {
        let discount = ambassador.discount;
        let commission = ambassador.commission;
        setModal({
            title: "Edit Ambassador",
            size: "md",
            message: <div>
                <div className={"form-group"}>
                    <label>Discount %</label>
                    <input type={"number"} className={"form-control"} defaultValue={discount} onChange={(e)=> discount = e.target.value}/>
                </div>
                <div className={"form-group"}>
                    <label>Commission €</label>
                    <input type={"number"} className={"form-control"} defaultValue={commission} onChange={(e)=> commission = e.target.value}/>
                </div>
            </div>,
            onSubmit: async () => {
                setLoading("editing")
                try {
                    await ambassadorService.editAmbassador(userAmbassador.id, {discount, commission});
                    setAmbassador({...ambassador, discount, commission});
                    cogoToast.success('Ambassador edited!', {
                        hideAfter: 5,
                        position: 'top-center',
                        heading: 'Success'
                    })
                } catch (e) {
                    cogoToast.error("Error while trying to edit ambassador!", {
                        hideAfter: 5,
                        position: 'top-center',
                        heading: 'Error'
                    })
                } finally {
                    setLoading("");
                }
                setModal(m=>({...m, hide: true}));
            }
        })
    }

    const handlePayWithdrawal = async (withdrawal) => {
        try {
            setLoading("paying")
            console.log("paying withdrawal", withdrawal)
            await ambassadorService.payWithdrawal(userAmbassador.id, withdrawal.id);
            setAmbassador({...ambassador, withdrawals: ambassador.withdrawals.map(w => w.id === withdrawal.id ? {...w, paid: new Date()} : w)});
            cogoToast.success('Withdrawal marked as paid!', {
                hideAfter: 5,
                position: 'top-center',
                heading: 'Success'
            })
        } catch (e) {
            cogoToast.error("Error trying to pay withdrawal!", {
                hideAfter: 5,
                position: 'top-center',
                heading: 'Error'
            })
        } finally {
            setLoading("");
        }

    }

    return (
        <div className={"AmbassadorCard card"}>
            <div className={"AmbassadorCard__personal"}>
                <img src={userAmbassador.photo} className={"photo"}/>
                <div>
                    <Link to={"/tenants/"+userAmbassador.id}><h4>{userAmbassador.name}</h4></Link>
                </div>
            </div>

            <div className={"AmbassadorCard__stats"}>
                <div className={"AmbassadorCard__stats__item"}>
                    <h6>Became Ambassador</h6>
                    <h5>{moment(ambassador.registration).format("DD-MM-YY")}</h5>
                </div>
                <div className={"AmbassadorCard__stats__item"}>
                    <h6>Code</h6>
                    <h5><strong>{ambassador.code}</strong></h5>
                </div>
                <div className={"AmbassadorCard__stats__item"}>
                    <h6>Requests</h6>
                    <h5>{ambassador.requesters}</h5>
                </div>
                <div className={"AmbassadorCard__stats__item"}>
                    <h6>Confirmed Reservations</h6>
                    <h5>{ambassador.bookings_confirmed}</h5>
                </div>
                <div className={"AmbassadorCard__stats__item"}>
                    <h6>Value Earned</h6>
                    <h5>{ambassador.value_earned}€</h5>
                </div>
                <div className={"AmbassadorCard__stats__item"}>
                    <h6>Discount</h6>
                    <h5>{ambassador.discount}%</h5>
                </div>
                <div className={"AmbassadorCard__stats__item"}>
                    <h6>Pay Per Booking</h6>
                    <h5>{ambassador.commission}€</h5>
                </div>
            </div>
            {ambassador.withdrawals.length > 0 && user.permissions.includes("ambassadors_pay") && <div className={"AmbassadorCard__withdrawals"}>
                <h6>Withdrawals</h6>
                    <table className={"table"}>
                        <thead>
                        <tr>
                            <th>Requested</th>
                            <th>Amount</th>
                            <th>Paid</th>
                        </tr>
                        </thead>
                        <tbody>
                        {ambassador.withdrawals.map(w => <tr key={w.id}>
                            <td>{moment(w.date).format("DD-MM-YY HH:mm")}</td>
                            <td>{w.value}€</td>
                            <td>{w.paid ? moment(w.paid).format("DD-MM-YY HH:mm") : <button className={"btn btn-success"} onClick={() => handlePayWithdrawal(w)}>{loading === "paying" ? <Spinner inline small/> : "Pay"}</button>}</td>
                        </tr>)}
                        </tbody>
                    </table>
            </div>}
            {user.permissions.includes("ambassadors_edit") && <button className={"btn btn-primary btn-true-sm mb-1"} style={{width: "100%"}} onClick={handleEdit}>{loading === "editing" ? <Spinner inline small/> : "Edit"}</button>}
        </div>
    )
}


export default AmbassadorCard;