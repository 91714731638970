import {get, patch, post} from "./index.js";

async function getEmailsElastic(paginationKey, searchKey, filters) {
    try {
        return await get('/backoffice/getEmailsElastic', {paginationKey,searchKey,filters});
    } catch (e) {
        return e;
    }
}

async function renderEmail(email, lang) {
    try {
        return await post('/backoffice/getEmailRender', {email, lang});
    } catch (e) {
        return e;
    }
}

export const emailService = {
    getEmailsElastic,
    renderEmail
};