import React, { Component } from 'react';

class Footer extends Component {
  render () {
    return (
      <footer className="footer">
        <div className="container-fluid">
          <div className="d-sm-flex justify-content-center justify-content-sm-between">
            <span className="text-muted text-center text-sm-left d-block d-sm-inline-block" style={{paddingTop:6}}>Backoffice Inlife - {new Date().getFullYear()}</span>
            <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center"><img alt={''} style={{height:25,width:25}} src={require('../../assets/images/appx_icon.svg')}/></span>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
