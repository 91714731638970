import {useTranslation} from "react-i18next";
import cogoToast from "cogo-toast";
import moment from "moment";
import {Button, Modal} from "react-bootstrap";
import React, {useEffect, useRef, useState} from "react";
import PageWrapper from "../components/PageWrapper";
import DataCardTable from "../components/DataCardTable";
import {reservationService} from "../../services/reservationService";
import {invoiceService} from "../../services/invoiceService";
import {formatCurrency, getStay} from "../shared/utils";
import {landlordService} from "../../services/landlordService";
import Select from "react-select";
import {Link} from "react-router-dom";

const ChooseReservationModal = ({showModal, setShowModal, invoice, landlordsList}) => {

    const [landlordReservations, setLandlordReservations] = useState([]);
    const [selectedLandlord, setSelectedLandlord] = useState(null);
    const [selectedReservation, setSelectedReservation] = useState(null);

    useEffect(() => {
        if(invoice.landlord?.id && !selectedLandlord) {
            setSelectedLandlord(landlordsList.find(l => l.value === invoice.landlord.id))
        }
    }, [landlordsList])

    useEffect(() => {
        if(selectedLandlord) {
            (async ()=> {
                let reservations = await landlordService.getConfirmedReservations(selectedLandlord.value);
                setLandlordReservations(reservations.sort((a, b) =>  new Date(b.confirmedDate) - new Date(a.confirmedDate)));
            })();
        }
    }, [selectedLandlord]);

    return (<Modal
        size="lg"
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
    >
        <Modal.Header closeButton>
            <Modal.Title>Choose reservation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className={"d-flex flex-column"}>
                <span className={"mb-2"}>Select the reservation for this invoice</span>
                <div>Invoice data:</div>
                <span>Date: {moment(invoice.date).format("DD/MM/YYYY HH:mm")}</span>
                <span>Due Date: {moment(invoice.dueDate).format("DD/MM/YYYY HH:mm")}</span>
                <label htmlFor={"landlord"}>Landlord:</label>
                <Select className={"mb-3"} name={"landlord"} options={landlordsList} value={[selectedLandlord]} placeholder={"Choose Landlord"} onChange={(o) => setSelectedLandlord(o)}/>
                <label htmlFor={"landlord"}>Reservation:</label>
                {landlordReservations && landlordReservations.map(r => {
                    return <div className={"d-flex mb-2"} style={{border: selectedReservation === r.id ? "1px solid #1d9afa" : ""}} onClick={() => setSelectedReservation(r.id)} key={"reservation-" + r.id}>
                        <span>{"Confirmed: " + moment(r.confirmedDate).format("DD/MM/YYYY HH:mm")}</span>
                        <span> | Tenant: {r.user.name}</span>
                    </div>
                })}
            </div>
        </Modal.Body>

        <Modal.Footer>
            <Button variant="success m-2" onClick={async () => {
                if(selectedReservation) await invoiceService.assignReservation(invoice.id, selectedReservation)
                cogoToast.success('Reservation assigned successfully.', {
                    hideAfter: 5,
                    position: 'top-center',
                    heading: 'Success'
                })
            }}>Submit</Button>
            <Button variant="light mb-" onClick={() => setShowModal(false)}>Cancel</Button>
        </Modal.Footer>
    </Modal>)
}

const Invoices = ({inline, overrideFetch, landlord, cardTableStyles}) => {

    const {t} = useTranslation();

    const [showModal, setShowModal] = useState(false);
    const [landlordsList, setLandlordsList] = useState([]);

    useEffect(() => {
        (async ()=>{
            let {landlords: _landlords} = await landlordService.list(null, null, null, 10000);
            let _landlordsList = _landlords.map(l => ({value: l.id, label: `${l.name} | ${l.email}`}));
            setLandlordsList(_landlordsList);
        })();
    }, []);

    const conditionalRowStyles = [
        {
            when: row => !row.reservation,
            style: {
                backgroundColor: '#e8e8e8',
            },
        },
        {
            when: row => !row.landlord,
            style: {
                backgroundColor: '#e8e8e8',
            },
        }
    ]

    const seePaymentDetails = (reservation) => async () => {
        if(!reservation?.payment?.method) return;
        if(reservation.payment.method === "stripe") {
            window.open("https://dashboard.stripe.com/payments/" + reservation.payment.auth, "_blank");
        } else if (reservation.payment.method === "paypal") {
            let start_date = moment(reservation.paymentDate).subtract(1, "day").format("YYYY-MM-DD");
            let end_date = moment(reservation.paymentDate).add(1, "day").format("YYYY-MM-DD");
            let sel_id;
            if(reservation.payment.status === "auth") {
                sel_id = reservation.payment.auth;
            } else if (reservation.payment.status === "paid") {
                try {
                    let paymentInfo = await reservationService.getPaypalPaymentInfo(reservation.payment.id);
                    sel_id = paymentInfo?.transactions?.[0]?.related_resources?.[0]?.sale?.id ||
                        paymentInfo?.transactions?.[0]?.related_resources?.[1]?.capture?.id ||
                        paymentInfo?.transactions?.[0]?.related_resources?.[0]?.authorization?.id;
                } catch (e) {
                    return window.open("https://www.paypal.com/activity/payment/" + (reservation.payment.auth||reservation.payment.id), "_blank");
                }
            }
            window.open(process.env.REACT_APP_PAYPAL_URL + "/myaccount/transactions/?free_text_search=" + sel_id + "&start_date=" + start_date + "&end_date=" + end_date, "_blank");
        }
    }

    const debounceHandler = useRef(null);

    const updateNote = async (invoice, e) => {
        let newNote = e.target.value;
        clearTimeout(debounceHandler.current);
        debounceHandler.current = setTimeout(async () => {
            await invoiceService.updateNote(invoice.id, newNote);
            invoice.notes = newNote;
        }, 1000);
    }


    const dataColumns = [
        {
            name: 'Reservation Id',
            selector: row => row.type,
            cell: row => <div data-tag="allowRowEvents">{row.reservation ? row.reservation?.id?.split("-")[0] || "-" : <><button className={"btn btn-primary"} onClick={() => setShowModal(true)}>Select</button> <ChooseReservationModal landlordsList={landlordsList} invoice={row} showModal={showModal} setShowModal={setShowModal}/></>}</div>
        },
        {
            name: 'ID',
            width: "120px",
            selector: row => row.id,
            cell: row => <div data-tag="allowRowEvents">{"#" + row.id?.split("-")[0]}</div>
        },
        {
            name: 'Reservation Request Date',
            selector: row => row.reservation?.requestDate,
            cell: row => <div data-tag="allowRowEvents">{row.reservation ? moment(row.reservation?.requestDate).format("DD/MM/YY") : "-"}</div>
        },
        {
            name: 'Type',
            selector: row => row.type,
            cell: row => <div data-tag="allowRowEvents">{row.type || "-"}</div>
        },
        {
            name: 'Landlord',
            selector: row => row.landlord,
            cell: row => <div data-tag="allowRowEvents"><Link to={`/landlords/${row.landlord?.id}`}>{row.landlord?.name}</Link></div>
        },
        {
            name: 'User',
            selector: row => row.landlord,
            cell: row => <div data-tag="allowRowEvents"><Link to={`/tenants/${row.user?.id || row.reservation?.user?.id}`}>{row.user?.name || row.reservation?.user?.name}</Link></div>
        },
        {
            name: 'Invoice Date',
            selector: row => row.date,
            cell: row => <div data-tag="allowRowEvents">{row.date ? moment(row.date).format("DD/MM/YY") : "-"}</div>
        },
        {
            name: 'Due Date',
            selector: row => row.dueDate,
            cell: row => <div data-tag="allowRowEvents">{row.dueDate ? moment(row.dueDate).format("DD/MM/YY") : "-"}</div>
        },
        {
            name: 'Total Paid',
            right: true,
            maxWidth: "100px",
            selector: row => row.reservation?.payment,
            cell: row => row.reservation?.payment?.bookingFee && row.reservation?.payment?.firstRent && <span className="text-capitalize" onClick={seePaymentDetails(row.reservation)}>{formatCurrency(row.reservation?.payment?.bookingFee + row.reservation?.payment?.firstRent + row.reservation?.payment?.extraTenantsValue)}</span>
        },
        {
            name: 'Notes',
            minWidth: "140px",
            selector: row => row.notes,
            cell: row => <textarea onChange={(e) => updateNote(row, e)} rows="2" defaultValue={row?.notes}/>
        },
        {
            name: 'Invoice',
            center: true,
            selector: row => row.id,
            cell: row => <div className={`${row.permalink ? "clickable" : ""}`} data-tag="allowRowEvents" onClick={() => row.permalink && window.open(row.permalink, '_blank').focus()}><i className={"mdi mdi-download"} /></div>
        }
    ]

    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem("invoices_filters")) || {});


    return (
        <PageWrapper breadcrumbs={[{title: "Invoices"}]}
                     title={"Invoices"}
                     inline={inline}
                     filters={filters} setFilters={setFilters}>
            <DataCardTable
                customClass={cardTableStyles}
                fixedFirstColumn
                columns={dataColumns}
                dataType={"invoices"}
                fetchData={overrideFetch || invoiceService.list}
                conditionalRowStyles={conditionalRowStyles}
                filters={filters}
            />
        </PageWrapper>

    )
};

export default Invoices;
