import {Form, FormGroup, OverlayTrigger, Tooltip} from "react-bootstrap";
import React, {useEffect, useMemo, useState} from "react";
import Select from "react-select";
import {get} from "../../services";
import { v4 as uuidv4 } from 'uuid';
import {toCapitalize} from "../shared/utils";



const Details = ({property, handleChange}) => {

    const {
        internal_name,
        integration_id,
        area,
        studio_rooms,
        bedrooms,
        renting,
        people,
        studio_types,
        bedroom_types,
        distMetro,
        distSupermarket,
        universities,
        metro_lines,
        features,
        adjectives,
        accommodation,
        has_rooms,
        validated,
        rooms
    } = property;

    let adjectivesNames = [
        {value: 'luminous', label: 'Luminous'},
        {value: 'comfortable', label: 'Comfortable'},
        {value: 'spacious', label: 'Spacious'},
        {value: 'modern', label: 'Modern'},
        {value: 'new', label: 'New'},
        { value: 'renovated', label: 'Renovated' },
        { value: 'cozy', label: 'Cozy' },
        { value: 'bright', label: 'Bright' }]


    let featuresOptions = useMemo(() => ['downtown', 'metroBus', 'foreigners', 'young', 'nightlife', 'restaurants', 'greenAreas', 'quiet', 'beach', 'bikeLanes', 'coworking', 'river', 'gyms', 'sights', 'venues'].map(option => ({
        value: option,
        label: toCapitalize(option)
    })), []);

    let metroLinesOptions = useMemo(() => (property.region === 'Lisboa' ? ['blue', 'yellow', 'red', 'green'] : ['blue', 'yellow', 'red', 'green', 'orange', 'violet', 'pink']).map(option => ({
        value: option,
        label: toCapitalize(option)
    })), [property.region]);

    const[universitiesOptions, setUniversitiesOptions] = useState([]);
    const [selectError, setSelectError] = useState(null);

    const getPropertyUniversities = () => {
        let propertyUniv = [];
        for (let uni in universities) {
            propertyUniv.push({value: universities[uni], label: universities[uni]})
        }
        return propertyUniv;
    }

    const getPropertyMetroLines = () => {
        let metroLines = [];
        for (let line in metro_lines) {
            metroLines.push({value: metro_lines[line], label: toCapitalize(metro_lines[line].toString())})
        }
        return metroLines;
    }

    const getPropertyFeatures = () => {
        let propertyFeatures = [];
        for (let index in features) {
            propertyFeatures.push({value: features[index], label: toCapitalize(features[index])})
        }
        return propertyFeatures;
    }

    const getPropertyAdjectives = () => {
        let propertyAdj = [];
        for (let index in adjectives) {
            propertyAdj.push({value: adjectives[index], label: toCapitalize(adjectives[index])})
        }
        return propertyAdj;
    }

    useEffect(() => {
        // get the university options to pass to the ReactSelectMulti
        if (!property.region) return;
        (async () => {
            let nextUniversitiesOptions = (await get('/data/universities'))[property.region].map(university => ({
                label: university,
                value: university
            }));
            setUniversitiesOptions(nextUniversitiesOptions);})()
    }, [property.region] )

    useEffect(()=>{
        let fieldsToCheck = ['studio_types', 'renting', 'bedroom_types'];
        if(isNaN(renting)) {
            handleChange('renting', 0);
        }
        if (accommodation === 'residence') {
            for (let i in fieldsToCheck) {
                if (isNaN(property[fieldsToCheck[i]])) {
                    handleChange(fieldsToCheck[i], 0);
                }
            }
        }
    }, [])


    const roomsChangeHandler = (num, type) => {
        let diff;
        switch (type){
            case 'bedroom_types':
                diff = bedroom_types - num;
                if(diff < 0){
                    let roomsLength = Object.keys(rooms).length;
                    while(roomsLength > bedroom_types){
                        rooms["room_"+(roomsLength+1)] = rooms ["room_"+(roomsLength)]
                        roomsLength--;
                    }
                    rooms["room_"+(num)] = {id: uuidv4(), photos:{}};
                    handleChange('renting', renting + 1)
                }
                if(diff > 0){
                    let numMov = studio_types;
                    let position = num + 1;
                    while(numMov > 0){
                        rooms["room_"+position] = rooms["room_"+(position+1)];
                        position++;
                        numMov--;
                    }
                    delete rooms["room_"+(position)];
                    handleChange('renting', renting - 1)
                }
                diff = 0;
                break;
            case 'studio_types':
                diff = studio_types - num;
                break;
            case 'renting':
                diff = renting - num;
                break;
            default:
                diff = 0;
                break;
        }
        if(diff < 0){
            let room_id = "room_" + (renting+1);
            rooms[room_id] = {id: uuidv4(), photos:{}};
            handleChange('renting', renting + 1)
        }
        if(diff > 0){
            delete rooms["room_"+(renting)];
            handleChange('renting', renting - 1)
        }
        handleChange(type, num);
        handleChange('rooms', rooms);

    }


    return (
        <div className={"PropertyEdit_column"}>
            <label className={"PropertyEdit_column-label"}>Details</label>
            <FormGroup>
                <label className={"PropertyEdit_input-label"}>Internal property name</label>
                <Form.Control type="text" className="form-control" name="internal_name" id="internal_name"
                              defaultValue={internal_name} placeholder="Internal name"
                              onChange={(e) => handleChange(e.target.name, e.target.value)}/>
            </FormGroup>
            <FormGroup>
                <label className={"PropertyEdit_input-label"}>Integration ID</label>
                <OverlayTrigger
                    overlay={<Tooltip className={`tooltip-blue`} id={`tooltip-blue`}>
                        Attention: Changing this attribute could cause <strong>duplicated</strong> houses, please double check.
                    </Tooltip>}>
                    <Form.Control type="text" className="form-control" name="integration_id" id="integration_id"
                                  defaultValue={integration_id} placeholder="Integration ID"
                                  onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                </OverlayTrigger>
            </FormGroup>
            <FormGroup>
                <label className={"PropertyEdit_input-label"}>Property size (&#13217;)</label>
                <Form.Control type="number" className="form-control" min={0} name="area" id="area" defaultValue={area}
                              placeholder="Property area"  onChange={(e) => handleChange(e.target.name, e.target.valueAsNumber || 0)}/>
            </FormGroup>
            {accommodation === 'residence' &&
            <>
                <div className={"PropertyEdit_row-inputs"} onChange={(e)=> handleChange(e.target.name, e.target.valueAsNumber || 0)}>
                    <FormGroup>
                        <label className={"PropertyEdit_input-label"}>How many studios are there in total?</label>
                        <Form.Control type="number" className="form-control" min={0} name="studio_rooms"
                                      id="studio_rooms" defaultValue={studio_rooms ? studio_rooms : null}/>
                    </FormGroup>
                    <FormGroup>
                        <label className={"PropertyEdit_input-label"}>How many rooms are there in total?</label>
                        <Form.Control type="number" className="form-control" min={0} name="bedrooms" id="bedrooms"
                                      defaultValue={bedrooms} placeholder="Number of bedrooms"/>
                    </FormGroup>
                </div>
                <div className={"PropertyEdit_row-inputs"} onChange={(e) => roomsChangeHandler(e.target.valueAsNumber || 0, e.target.name)}>
                    <FormGroup>
                        <label className={"PropertyEdit_input-label"}>How many studio categories does the property have?</label>
                        <Form.Control type="number" className="form-control" min={0} name="studio_types"
                                      id="studio_types" defaultValue={studio_types ? property.studio_types : null}
                                      />
                    </FormGroup>
                    <FormGroup>
                        <label className={"PropertyEdit_input-label"}>How many room categories does the property have?</label>
                        <Form.Control type="number" className="form-control" min={0} name="bedroom_types"
                                      id="bedroom_types" defaultValue={bedroom_types}
                                      placeholder="Number of categories"/>
                    </FormGroup>
                </div>
            </>}
            {accommodation === 'apartment' &&
            <>
                <div className={"PropertyEdit_row-inputs"}>
                    <label className={"PropertyEdit_input-label mt-2 mr-1"}>Do you have rooms?</label>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="checkbox" className="form-check-input"
                                   id={'has_rooms'}
                                   defaultChecked={has_rooms} value={!has_rooms} name={'has_rooms'}
                                   onChange={(e) => handleChange(e.target.name, e.target.value === 'true')}
                            />
                            <i className="input-helper"></i>
                            Yes
                        </label>
                    </div>
                </div>
                {has_rooms &&
                <div className={"PropertyEdit_row-inputs"}>
                    <FormGroup>
                        <label className={"PropertyEdit_input-label"}>How many rooms are on the property?</label>
                        <Form.Control type="number" className="form-control" min={0} name="bedrooms"
                                      id="bedrooms" defaultValue={bedrooms ? bedrooms : null}
                                      onChange={(e) => handleChange(e.target.name, e.target.valueAsNumber || 0)}/>
                    </FormGroup>
                    <FormGroup>
                        <label className={"PropertyEdit_input-label"}>How many of these rooms are you renting? include all even those who are now busy</label>
                        <Form.Control type="number" className="form-control" min={0} name="renting" id="renting"
                                      defaultValue={renting} placeholder="Number of bedrooms"
                                      onChange={(e) => roomsChangeHandler(e.target.valueAsNumber || 0, e.target.name)}/>
                    </FormGroup>
                </div>}
                <FormGroup>
                    <label className={"PropertyEdit_input-label"}>How many people can live in the house?</label>
                    <Form.Control type="number" className="form-control" min={0} name="people"
                                  id="people" defaultValue={people}
                                  onChange={(e) => handleChange(e.target.name, e.target.valueAsNumber || 0)}/>
                </FormGroup>
            </>
            }
            {accommodation === 'rooms' &&
            <>
                <div className={"PropertyEdit_row-inputs"}>
                    <FormGroup>
                        <label className={"PropertyEdit_input-label"}>How many rooms are on the property?</label>
                        <Form.Control type="number" className="form-control" min={0} name="bedrooms"
                                      id="bedrooms" defaultValue={bedrooms ? bedrooms : null}
                                      onChange={(e) => handleChange(e.target.name, e.target.valueAsNumber || 0)}/>
                    </FormGroup>
                    <FormGroup>
                        <label className={"PropertyEdit_input-label"}>How many of these rooms are you renting? Include all of them even those who are busy now</label>
                        <Form.Control type="number" className="form-control" min={0} name="renting" id="renting"
                                      defaultValue={renting} placeholder="Number of bedrooms"
                                      onChange={(e) => roomsChangeHandler(e.target.valueAsNumber || 0, e.target.name)}/>
                    </FormGroup>
                </div>
                <FormGroup>
                    <label className={"PropertyEdit_input-label"}>How many people will be able to live in the house? (maximum)</label>
                    <Form.Control type="number" className="form-control" min={0} name="people"
                                  id="people" defaultValue={people}
                                  onChange={(e) => handleChange(e.target.name, e.target.valueAsNumber || 0)}/>
                </FormGroup>
            </>}
            <div className={"mb-3"}>
                <label className={"PropertyEdit_input-label"}>Choose the adjectives that best characterize the property</label>
                <Select
                    isMulti={true}
                    options={adjectivesNames}
                    defaultValue={getPropertyAdjectives()}
                    onChange={(options => {
                        let list = [];
                        Object.keys(options || []).map((adj) => list.push(options[adj].value));
                        handleChange('adjectives', list)
                    })}
                />
            </div>
            <div className={"PropertyEdit_row-inputs"} onChange={(e) => handleChange(e.target.name, e.target.valueAsNumber || 0)}>
                <FormGroup>
                    <label className={"PropertyEdit_input-label"}>Walking distance to the nearest metro or bus:</label>
                    <Form.Control type="number" className="form-control" min={0} name="distMetro" id="distMetro"
                                  defaultValue={distMetro} placeholder="Ex.: 3min"/>
                </FormGroup>
                <FormGroup>
                    <label className={"PropertyEdit_input-label"}>Walking distance to the nearest supermarket:</label>
                    <Form.Control type="number" className="form-control" min={0} name="distSupermarket"
                                  id="distSupermarket" defaultValue={distSupermarket} placeholder="Ex.: 5min"/>
                </FormGroup>
            </div>
            <div className={"mb-3"}>
                <label className={"PropertyEdit_input-label mr-1"}>Nearby Universities:</label>
                <small className={selectError==="universities" ? 'text-warning' : ''}>You can choose up to 3 options</small>
                <Select
                    isMulti={true}
                    options={universitiesOptions}
                    value={getPropertyUniversities()}
                    onChange={(options => {
                        if(Object.keys(options || []).length > 3){
                            setSelectError("universities");
                            setTimeout(()=>{
                                setSelectError(null);
                            }, 3000)
                            return;
                        }
                        let list = [];
                        Object.keys(options || []).map((uni) => list.push(options[uni].value));
                        handleChange('universities', list)
                    })}
                />
            </div>
            <div className={"mb-3"}>
                <label className={"PropertyEdit_input-label"}>Nearby metro lines:</label>
                <Select
                    isMulti={true}
                    options={metroLinesOptions}
                    defaultValue={getPropertyMetroLines()}
                    onChange={(options => {
                        let list = [];
                        Object.keys(options || []).map((line) => list.push(options[line].value));
                        handleChange('metro_lines', list)
                    })}
                />
            </div>
            <div className={"mb-3"}>
                <label className={"PropertyEdit_input-label mr-1"}>Features to highlight:</label>
                <small className={selectError==="features" ? 'text-danger' : ''}>You can choose up to 3 options</small>
                <Select
                    isMulti={true}
                    options={featuresOptions}
                    value={getPropertyFeatures()}
                    onChange={(options => {
                        if(Object.keys(options || []).length > 3){
                            setSelectError("features");
                            setTimeout(()=>{
                                setSelectError(null);
                            }, 3000)
                            return;
                        }
                        let list = [];
                        Object.keys(options || []).map((feat) => list.push(options[feat].value));
                        handleChange('features', list)
                    })}
                />
            </div>
        </div>
    )
}

export default Details;