import React, {useRef, useState} from 'react'
import {useTranslation} from 'react-i18next';
import {useDialogContext} from "../App";
import PageWrapper from "../components/PageWrapper";
import DataCardTable from "../components/DataCardTable";
import {Link} from "react-router-dom";
import {ambassadorService} from "../../services/ambassadorService";
import moment from 'moment';
import AmbassadorCard from "../components/AmbassadorCard";
import cogoToast from "cogo-toast";


const Ambassadors = ({inline, overrideFetch}) => {

    const {t} = useTranslation();
    const {setModal} = useDialogContext();

    const editAmbassador = useRef({
        type: "ambassador",
        name: "",
        code: "",
        code_type: "percent",
        discount: 0,
        email: "",
        password: "",
        commission: 0
    });

    const handleChangeEditAmbassador = (e) => {
        let val = e.target.value;
        let tname = e.target.name;
        if(tname === "discount" || tname === "commission") {
            val = parseInt(val);
        }
        editAmbassador.current[tname] = val;
    }

    const EditAmbassador = () => {
        return <div className={"EditAmbassadorForm edit-form"}>
            <div className="form-group mb-2">
                <label>Type</label>
                <select className={"form-control"} name={"type"} defaultValue={editAmbassador.current.type} onChange={handleChangeEditAmbassador}>
                    <option value={"ambassador"}>Ambassador</option>
                    <option value={"partner"}>Partner</option>
                </select>
            </div>
            <div className="form-group mb-2">
                <label>Name</label>
                <input type="text" className="form-control" name={"name"} defaultValue={editAmbassador.current.name} onChange={handleChangeEditAmbassador}/>
            </div>
            <div className="form-group mb-2">
                <label>Email</label>
                <input type="email" className="form-control" name={"email"} defaultValue={editAmbassador.current.email} onChange={handleChangeEditAmbassador}/>
            </div>
            <div className="form-group mb-2">
                <label>Password (leave empty to auto generate)</label>
                <input type="password" className="form-control" name={"password"} defaultValue={editAmbassador.current.password} onChange={handleChangeEditAmbassador}/>
            </div>
            <div className="form-group mb-2">
                <label>Code (leave empty to auto generate)</label>
                <input type="text" className="form-control" name={"code"} defaultValue={editAmbassador.current.code} onChange={handleChangeEditAmbassador}/>
            </div>
            <div className="form-group mb-2">
                <label>Code Type</label>
                <select className={"form-control"} name={"code_type"} defaultValue={editAmbassador.current.code_type} onChange={handleChangeEditAmbassador}>
                    <option value={"percent"}>Percent</option>
                    <option value={"fixed"}>Fixed</option>
                </select>
            </div>
            <div className="form-group mb-2">
                <label>Discount Value</label>
                <input type="number" className="form-control" name={"discount"} defaultValue={editAmbassador.current.discount} onChange={handleChangeEditAmbassador}/>
            </div>
            <div className="form-group mb-2">
                <label>Comission (PPB)</label>
                <input type="number" className="form-control" name={"commission"} defaultValue={editAmbassador.current.commission} onChange={handleChangeEditAmbassador}/>
            </div>
        </div>
    }

    const handleAddAmbassador = () => {
        setModal({
            title: "Add new Ambassador/Partner",
            size: "md",
            message: <EditAmbassador/>,
            onSubmit: handleCreateAmbassador
        })
    }

    const handleCreateAmbassador = async () => {
        try {
            await ambassadorService.createAmbassador(editAmbassador.current);
            cogoToast.success("Ambassador created successfully", {
                hideAfter: 5,
                position: 'top-center',
                heading: 'Success'
            });
            setModal(m=>({...m, hide: true}));
        } catch (e) {
            console.error(e);
            cogoToast.error(e?.error?.message, {
                hideAfter: 5,
                position: 'top-center',
                heading: 'Error'
            })
        }
    }

    const conditionalRowStyles = [
        {
            when: row => (row.ambassador.withdrawals.some(r => !r.paid)),
            style: {
                backgroundColor: '#FCE5C1',
            },
        }
    ];

    const dataColumns = [
        {
            name: 'User ID',
            width: "100px",
            selector: row=>row.id,
            cell: row => <div data-tag="allowRowEvents">{"#"+row.id?.split("-")[0]}</div>
        },
        {
            name: 'Name',
            grow: 1.5,
            selector: row => row.name,
            cell: row => <div className={"text-capitalize"}><Link to={`/tenants/${row.id}`}>{row.name}</Link></div>
        },
        {
            name: "Became Ambassador",
            selector: row => row.ambassador.registration,
            cell: row => <div>{moment(row.ambassador.registration).format("DD-MM-YY")}</div>
        },
        {
            name: "Code",
            selector: row => row.ambassador.code,
            cell: row => <div>{row.ambassador.code}</div>
        },
        {
            name: "Requesters",
            selector: row => row.ambassador.requesters,
            cell: row => <div>{row.ambassador.requesters}</div>
        },
        {
            name: "Bookings Confirmed",
            selector: row => row.ambassador.bookings_confirmed,
            cell: row => <div>{row.ambassador.bookings_confirmed}</div>
        },
        {
            name: "Value Earned",
            selector: row => row.ambassador.value_earned,
            cell: row => <div>{row.ambassador.value_earned}</div>
        },
        {
            name: "Discount",
            selector: row => row.ambassador.discount,
            cell: row => <div>{row.ambassador.discount}%</div>
        },
        {
            name: "PPB",
            selector: row => row.ambassador.commission,
            cell: row => <div>{row.ambassador.commission}€</div>
        },

    ]

    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem("ambassadors_filters")) || {});

    return (
        <PageWrapper hideTitle
                     title={"Ambassadors"}
                     inline={inline}
                     filters={filters} setFilters={setFilters}>
            <DataCardTable
                extraHeaderRenderer={() => <button className={"btn btn-success mr-2"} onClick={handleAddAmbassador}><i className={"mdi mdi-plus"}/>{"Add new"}</button>}
                cardRendererComponent={AmbassadorCard}
                fixedFirstColumn
                columns={dataColumns}
                dataType={"ambassadors"}
                fetchData={overrideFetch || ambassadorService.listAll}
                filters={filters}
                conditionalRowStyles={conditionalRowStyles}
            />
        </PageWrapper>
    )

}

export default Ambassadors;
