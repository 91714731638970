import React from 'react';
import {Form, FormGroup} from "react-bootstrap";


const BathRoomForm = ({bathroom, numBathrooms, index, handleChange}) =>{
    let bathroomName = "bathroom_"+index;

    return(
        <>
            <label
                className={"PropertyEdit_column-label-seconday"}>{'WC ' + (numBathrooms > 1 ? index+1 : '')}</label>
            <FormGroup>
                <label className={"PropertyEdit_input-label"}>Kitchen size (&#13217;):</label>
                <Form.Control type="number" className="form-control" min={0} name="size" id={bathroomName+"-size"}
                              defaultValue={bathroom.size}
                              placeholder="Ex.: 30 m2" onChange={(e)=>handleChange(bathroomName, e.target.name, e.target.valueAsNumber || 0)}/>
            </FormGroup>
            <div className={'d-flex flex-row Area-features'} onChange={(e)=>handleChange(bathroomName, e.target.name, e.target.value)}>
                <div className={'d-flex flex-column'}>
                    <div className={"d-inline-flex justify-content-between"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Toilet</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={bathroom.toilet} className="form-check-input" name={'toilet'}
                                       value={!bathroom.toilet}/>
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                    <div className={"d-inline-flex justify-content-between"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Lavatory</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={bathroom.sink} className="form-check-input" name={'sink'}
                                       value={!bathroom.sink}/>
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                    <div className={"d-inline-flex justify-content-between"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Shower</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={bathroom.shower} className="form-check-input" name={'shower'}
                                       value={!bathroom.shower}/>
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                    <div className={"d-inline-flex justify-content-between"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Bathtub</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={bathroom.bathtub} className="form-check-input" name={'bathtub'}
                                       value={!bathroom.bathtub}/>
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                </div>
                <div className={'d-flex flex-column'}>
                    <div className={"d-inline-flex justify-content-between"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Mirror</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={bathroom.mirror} className="form-check-input" name={'mirror'}
                                       value={!bathroom.mirror}/>
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                    <div className={"d-inline-flex justify-content-between"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Hairdryer</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={bathroom.hairdryer} className="form-check-input" name={'hairdryer'}
                                       value={!bathroom.hairdryer}/>
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                    <div className={"d-inline-flex justify-content-between"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Window to the outside</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={bathroom.window} className="form-check-input" name={'window'}
                                       value={!bathroom.window}/>
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                    <div className={"d-inline-flex justify-content-between"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Whirlpool</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={bathroom.jacuzzi} className="form-check-input" name={'jacuzzi'}
                                       value={!bathroom.jacuzzi}/>
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default BathRoomForm;