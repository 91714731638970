import React, {useRef, useState} from 'react'
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {useDialogContext} from "../App";
import PageWrapper from "../components/PageWrapper";
import DataCardTable from "../components/DataCardTable";
import TenantCard from "../components/TenantCard";
import {tenantService} from "../../services/tenantService";
import {formatCurrency} from "../shared/utils";
import {toCapitalize} from "../shared/utils";
import {Link} from "react-router-dom";


const Tenants = ({inline, overrideFetch}) => {

    const {t} = useTranslation();
    const {setModal} = useDialogContext();

    const debounceHandler = useRef(null);

    const updateNotes = async (user, notes) => {
        clearTimeout(debounceHandler.current);
        debounceHandler.current = setTimeout(async () => {
            user.notes = notes;
            await tenantService.updateNotes(user.id, notes);
        }, 1000);

    }

    const dataColumns = [
        {
            name: 'User ID',
            width: "100px",
            selector: row=>row.id,
            cell: row => <div data-tag="allowRowEvents">{"#"+row.id?.split("-")[0]}</div>
        },
        {
            name: 'Name',
            grow: 1.5,
            selector: row => row.name,
            cell: row => <div className={"text-capitalize"}><Link to={`/tenants/${row.id}`}>{row.name}</Link></div>
        },
        {
            name: 'Rating',
            width: "100px",
            selector: row=>row.rating,
            cell: row => <div className={"ratingStars"}>{Array.from({length: 5}, (_, i) => i + 1).map(n => {
                return <i key={row.id + "star-" + n}
                          style={{color: (n <= row.rating ? "yellow" : "#c9c9c9")}}
                          className={`mdi mdi-star`}/>
            })}</div>
        },
        {
            name: 'Nationality',
            width: "120px",
            selector: row => row.nationality ? t("country." + row.nationality) : '-'
        },
        {
            name: 'Email',
            minWidth: "160px",
            maxWidth: "200px",
            selector: row=>row.email,
            cell: row => (<div data-tag="allowRowEvents" className={"property-cell"}>
                <span>{row.email}</span>
            </div>)
        },
        {
            name: 'Phone',
            minWidth: "140px",
            selector: row=>row.phone,
            cell: row => (<div data-tag="allowRowEvents" className={"property-cell"}>
                <span>{row.dialCode + " " + row.phone}</span>
            </div>)
        },
        {
            name: 'Gender',
            maxWidth: "110px",
            selector: row=>row.gender,
            cell: row => <span>{(toCapitalize(row.gender)|| "N/A")}</span>
        },
        {
            name: 'Notes',
            minWidth: "140px",
            selector: row => row.notes,
            cell: row => <textarea onChange={(e) => updateNotes(row, e.target.value)} rows="2" defaultValue={row?.notes}/>
        },
        {
            name: 'City',
            maxWidth: "110px",
            selector: row=>row.city,
            cell: row => <span>{row.city}</span>
        },
        {
            name: 'Preferred Location',
            minWidth: "160px",
            selector: row=>row.location,
            cell: row => <span>{row.location ? (row[row.location] ? "Near " +row[row.location] + (row.location === "metro" ? " line" : "") : row.location) : "N.D"}</span>
        },
        {
            name: 'Property Type',
            maxWidth: "90px",
            selector: row => row.accommodation,
            cell: row => (<div data-tag="allowRowEvents" className={"property-cell"} style={{minWidth: "76px"}}>
                <span>{row.accommodation ? toCapitalize(row.accommodation) : ""}</span>
            </div>)
        },
        {
            name: 'Move-In',
            center: true,
            maxWidth: "110px",
            selector: row => row.moveIn,
            format: row => row.moveIn ? moment(row.moveIn).format("DD-MM-YYYY") : "-"
        },
        {
            name: 'Move-Out',
            center: true,
            maxWidth: "110px",
            selector: row => row.moveOut,
            format: row => row.moveOut ? moment(row.moveOut).format("DD-MM-YYYY") : "-"
        },
        {
            name: 'Nº Tenants',
            center: true,
            selector: row=>row.numTenants,
            cell: row => <span>{row.numTenants}</span>
        },
        {
            name: 'Nº Rooms',
            center: true,
            selector: row=>row.numRooms,
            cell: row => <span>{row.numRooms}</span>
        },
        {
            name: 'Budget',
            center: true,
            maxWidth: "110px",
            selector: row => row.bugetMax,
            format: row => formatCurrency([row.budgetMin, row.budgetMax])
        },
        {
            name: 'Reservations Paid',
            selector: row=>row.stats?.paidReservations,
            cell: row => <span>{row.stats?.paidReservations}</span>
        },
        {
            name: 'Visits Completed',
            selector: row=>row.stats,
            cell: row => <span>{row.stats?.completedVisits}</span>
        },
        {
            name: 'Active Offers',
            selector: row=>row.stats,
            cell: row => <span>{row.stats?.activeOffers}</span>
        }
    ]

    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem("tenants_filters")) || {});

    return (
        <PageWrapper breadcrumbs={[{title: "Tenants"}]}
                     title={"Tenants"}
                     inline={inline}
                     filters={filters} setFilters={setFilters}>
            <DataCardTable
                cardRendererComponent={TenantCard}
                fixedFirstColumn
                columns={dataColumns}
                dataType={"tenants"}
                fetchData={overrideFetch || tenantService.list}
                filters={filters}
            />
        </PageWrapper>
    )

}

export default Tenants;
