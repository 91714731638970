import React, {useEffect, useMemo, useState} from "react";
import {Form} from "react-bootstrap";
import moment from 'moment';
import {useAuth} from "../../services/authService";
import {dataService} from "../../services/dataService";
import Select from "react-select";
import {useTranslation} from "react-i18next";

const LandlordEditForm = ({editLandlord, setEditLandlord, isChecked, setIsChecked, validateIban}) => {

    let {user} = useAuth();
    const {t} = useTranslation();

    let {
        name,
        email,
        phone,
        dialCode,
        address,
        commission,
        topLandlord,
        notes,
        multipleFiscalInfo,
        multipleBankInfo,
        firstRentCommission,
        promotion,
        video
    } = editLandlord;

    const [noCommission, setNoCommission] = useState(!!editLandlord.noCommission);
    const [hasPromotion, setHasPromotion] = useState(promotion?.endDate && moment(promotion.endDate).isAfter(moment()));
    const [countries, setCountries] = useState([]);
    const [dialCodes, setDialCodes] = useState([]);

    useEffect(() => {
        (async () => {
            let _countries = await dataService.getCountries()
            setCountries(_countries.map(country => {
                return {
                    value: country.iso2,
                    label: t("country." + country.iso2),
                }
            }));
            setDialCodes(_countries.map(country => ({
                value: "+" + country.dial_code,
                label: "+" + country.dial_code
            })));

        })();
    }, []);

    const handleFieldChange = (e) => {
        if (e.persist) e.persist();
        let name = e.target.name;
        let value = e.target.value;
        let id = e.target.id;
        if (name === "noCommission") setNoCommission(value);
        if (name === "topLandlord") value = !topLandlord;
        if (name === "promotionCommission") value = {...promotion, commission: value};
        if (name === "promotionTime") value = {...promotion, endDate: moment().add(value, 'months').toISOString()};
        if (name.startsWith("promotion")) {
            name = "promotion";
            promotion = value;
        }
        if (name === "video") {
            let isValid;
            // reset?
            if (value) isValid = value.match(/^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/);
            value = isValid ? isValid[2] : null;
            if (value && !isValid?.[2]) {
                alert("Invalid YouTube URL");
                return;
            }
        }
        if (name === "vat" || name === "fiscalName" || name === "fiscalAddress" || name === "fiscalCountry") {
            if (!multipleFiscalInfo) multipleFiscalInfo = [];
            let index = multipleFiscalInfo?.findIndex(info => id ? info.id === id : info.default);
            if (name === "fiscalAddress") name = "address";
            if (name === "fiscalCountry") name = "country";
            if (index === -1) {
                multipleFiscalInfo.push({"default": true, [name]: value});
            } else {
                multipleFiscalInfo[index] = {
                    ...multipleFiscalInfo[index],
                    [name]: value,
                    "vatValidated": (name === "vat" ? false : multipleFiscalInfo[index].vatValidated)
                }
            }
            value = multipleFiscalInfo;
            name = "multipleFiscalInfo";
        }
        if (name === "iban" || name === "bank_fiscalName" || name === "bankCountry") {
            if (!multipleBankInfo) multipleBankInfo = [];
            let index = multipleBankInfo.findIndex(info => id ? info.id === id : info.default);
            if (name === "bank_fiscalName") name = "fiscalName";
            if (name === "bankCountry") name = "country";
            if (index === -1) {
                multipleBankInfo.push({"default": true, "iban": value});
            } else {
                multipleBankInfo[index] = {
                    ...multipleBankInfo[index],
                    [name]: value,
                    "valid": name === "iban" ? true : multipleBankInfo[index].valid
                }
            }
            value = multipleBankInfo;
            name = "multipleBankInfo";
        }
        setEditLandlord(prev => {
            return {...prev, [name]: value}
        })
        if (name === "video" && !value) alert("Success!");
    }

    return (
        <Form>
            {user?.permissions?.includes("landlords_edit_name") && <Form.Group>
                <label>Avatar</label>
                <div className="custom-file">
                    <Form.Control type="file" className="form-control visibility-hidden" id="profileImage"
                                  lang="pt"/>
                    <label className="custom-file-label" htmlFor="profileImage">Upload Image</label>
                </div>
            </Form.Group>}
            {user?.permissions?.includes("landlords_edit_name") && <Form.Group>
                <label htmlFor="name">Name</label>
                <Form.Control type="text" className="form-control" name="name" id="name" defaultValue={name}
                              placeholder="Name" onChange={handleFieldChange}/>
            </Form.Group>}
            <Form.Group>
                <label htmlFor="email">Email</label>
                <Form.Control type="email" className="form-control" id="email" name="email" defaultValue={email}
                              placeholder="Email" readOnly/>
            </Form.Group>
            {user?.permissions?.includes("landlords_edit_phone") && <Form.Group>
                <label htmlFor="phone">Dial code</label>
                {dialCodes.length > 0 && <Select
                    options={dialCodes}
                    placeholder={"+351"}
                    name={"dialCode"}
                    defaultValue={dialCodes.filter(code => code.value === dialCode)}
                    onChange={(o) => handleFieldChange({
                        target: {
                            name: "dialCode",
                            value: o.value
                        }
                    })}
                />}
            </Form.Group>}
            {user?.permissions?.includes("landlords_edit_phone") && <Form.Group>
                <label htmlFor="phone">Phone</label>
                <Form.Control type="text" className="form-control" id="phone" defaultValue={phone} name="phone"
                              placeholder="Phone" onChange={handleFieldChange}/>
            </Form.Group>}
            {user?.permissions?.includes("landlords_edit_address") && <Form.Group>
                <label htmlFor="address">Address</label>
                <Form.Control type="text" className="form-control" id="address" defaultValue={address} name="address"
                              placeholder="Address" onChange={handleFieldChange}/>
            </Form.Group>}
            {user?.permissions?.includes("landlords_edit_video") && <Form.Group>
                <div style={{"display": "flex", "alingItems": "center"}}>
                    <label htmlFor="video">Video Link</label>
                    <i onClick={() => handleFieldChange({target: {name: "video", value: undefined, id: "video"}})}
                       style={{"color": "red", "marginBottom": '0.6rem', "marginLeft": "0.5rem", "lineHeight": "1", "cursor": "pointer"}}
                       className={"mdi mdi-trash-can-outline action-reject"}/>
                </div>
                <Form.Control type="text" className="form-control" id="address" defaultValue={video} name="video"
                              placeholder="Ex.: www.youtube.com/watch?v=Dz1JMaliWdE" onChange={handleFieldChange}/>
            </Form.Group>}
            {user?.permissions?.includes("landlords_edit_fiscal") && (multipleFiscalInfo?.length > 0 ? multipleFiscalInfo : [{"default": true}]).map((fiscalInfo, index) => {
                return (
                    <div>
                        <label>Fiscal Info{index === 0 ? "(default)" : index + 1}</label>
                        <div style={{marginBottom: "1.5rem"}} className={"ml-3"} key={"fiscalInfo-" + index}>
                            <Form.Group>
                                <label htmlFor="vat">VAT</label>
                                {fiscalInfo.vatValidated && <span style={{color: "#2DC928"}}> Validated</span>}
                                <Form.Control type="text" className="form-control" id={fiscalInfo.id} name="vat"
                                              defaultValue={fiscalInfo?.vat} placeholder="Tax Identification Number"
                                              onChange={handleFieldChange}/>
                            </Form.Group>
                            <Form.Group>
                                <label htmlFor="fiscalName">Fiscal Name</label>
                                <Form.Control type="text" className="form-control" id={fiscalInfo.id}
                                              defaultValue={fiscalInfo?.fiscalName} name="fiscalName"
                                              placeholder="Fiscal Name"
                                              onChange={handleFieldChange}/>
                            </Form.Group>
                            <Form.Group>
                                <label htmlFor="fiscalAddress">Fiscal Address</label>
                                <Form.Control type="text" className="form-control" id={fiscalInfo.id}
                                              defaultValue={fiscalInfo?.address} name="fiscalAddress"
                                              placeholder="Address"
                                              autoComplete={"fiscal-address" + index}
                                              onChange={handleFieldChange}/>
                            </Form.Group>
                            {countries.length > 0 && <Select
                                options={countries}
                                placeholder={"Country"}
                                name={"fiscalCountry"}
                                defaultValue={countries.filter(country => country.value === fiscalInfo?.country)}
                                onChange={(o) => handleFieldChange({
                                    target: {
                                        id: fiscalInfo.id,
                                        name: "fiscalCountry",
                                        value: o.value
                                    }
                                })}
                            />}
                        </div>
                    </div>
                )
            })}
            {user?.permissions?.includes("landlords_edit_bank") && (multipleBankInfo?.length > 0 ? multipleBankInfo : [{"default": true}]).map((bankInfo, index) => {
                return (
                    <div>
                        <label>Bank Info{index === 0 ? "(default)" : index + 1}</label>
                        <div style={{marginBottom: "1.5rem"}} className={"ml-3"} key={"bankInfo-" + index}>
                            <Form.Group>
                                <div className={"d-flex align-items-center mb-1"}>
                                    <label htmlFor="iban" style={{marginBottom: "unset"}}>IBAN</label>
                                    {(bankInfo?.valid && bankInfo?.iban) &&
                                        <span style={{color: "#2DC928"}} className={"ml-1"}> Validated (by {bankInfo?.validatedBy || "undefined"} on {bankInfo.validatedOn && moment(bankInfo.validatedOn).format("DD/MM/YYYY") || "undefined"})</span>}
                                    {(bankInfo?.iban && !bankInfo?.valid && bankInfo?.proofDocumentUrl) && <>
                                        <button className={"btn btn-primary ml-2"}
                                                onClick={() => validateIban(editLandlord, bankInfo)}>Validate
                                        </button>
                                        <span onClick={() => window.open(bankInfo.proofDocumentUrl, "_blank")}>See proof document</span>
                                    </>}
                                    {(bankInfo?.iban && !bankInfo?.valid && !bankInfo?.proofDocumentUrl) && <span className={"ml-1"} style={{color: "#f3454e"}}>Not validated</span>}
                                </div>
                                <Form.Control type="text" className="form-control" id={bankInfo.id} name="iban"
                                              defaultValue={bankInfo?.iban ? bankInfo.iban.slice(0, 4) + "**************" + bankInfo.iban.slice(bankInfo.iban.length - 4, bankInfo.iban.length) : undefined} placeholder="Landlord's IBAN"
                                              readOnly/>
                            </Form.Group>
                            <Form.Group>
                                <label htmlFor="fiscalName">Name</label>
                                <Form.Control type="text" className="form-control" id={bankInfo.id}
                                              name="bank_fiscalName"
                                              defaultValue={bankInfo?.fiscalName} placeholder="Fiscal Name"/>
                            </Form.Group>
                            {countries.length > 0 && <Select
                                options={countries}
                                placeholder={"Country"}
                                name={"bankCountry"}
                                defaultValue={countries.filter(country => country.value === bankInfo?.country)}
                                onChange={(o) => handleFieldChange({
                                    target: {
                                        id: bankInfo.id,
                                        name: "bankCountry",
                                        value: o.value
                                    }
                                })}
                            />}
                        </div>
                    </div>
                )
            })}
            <Form.Group className={"check_box"}>
                <label htmlFor="topLandlord">Force Top Landlord</label>
                <Form.Control type="checkbox" className="form-control" id="topLandlord"
                              name="topLandlord" defaultChecked={isChecked}
                              onClick={() => {
                                  setIsChecked(!isChecked);
                                  handleFieldChange({target: {name: "topLandlord", value: (!isChecked).toString()}})
                              }}/>
            </Form.Group>
            {user?.permissions?.includes("landlords_edit_commission") && <Form.Group className="row">
                <label className="col-sm-3 col-form-label">Commission Model</label>
                <div className="col-sm-4">
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="firstRentCommission"
                                   id="overTotalValue" value={false} defaultChecked={!firstRentCommission}
                                   onChange={handleFieldChange}/> Over total contract value (default)
                            <i className="input-helper"/>
                        </label>
                    </div>
                </div>
                <div className="col-sm-5">
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="firstRentCommission"
                                   id="overFirstRent" value={true} defaultChecked={firstRentCommission}
                                   onChange={handleFieldChange}/> Over first rent
                            <i className="input-helper"/>
                        </label>
                    </div>
                </div>
            </Form.Group>}
            {user?.permissions?.includes("landlords_edit_commission") && <Form.Group className={"check_box"}>
                <label htmlFor="noCommission">No Commission (0%)</label>
                <Form.Control type="checkbox" className="form-control" id="noCommission"
                              name="noCommission" defaultChecked={noCommission}
                              onClick={() => {
                                  handleFieldChange({target: {name: "noCommission", value: !noCommission}})
                              }}/>
            </Form.Group>}
            {user?.permissions?.includes("landlords_edit_commission") && <Form.Group>
                <label htmlFor="commission">Commission</label>
                <Form.Control disabled={noCommission} type="number" className="form-control" id="commission"
                              name="commission" defaultValue={commission} placeholder="x %"
                              onChange={handleFieldChange}/>
            </Form.Group>}
            {user?.permissions?.includes("landlords_edit_commission") && <Form.Group className={"check_box"}>
                <label htmlFor="promotion">Give Promotion</label>
                <Form.Control type="checkbox" className="form-control" id="promotion"
                              name="promotion" defaultChecked={hasPromotion}
                              onClick={() => {
                                  if (hasPromotion) handleFieldChange({
                                      target: {
                                          name: "promotion",
                                          value: "$_REMOVE_$"
                                      }
                                  })
                                  setHasPromotion(prev => !prev)
                              }}/>
            </Form.Group>}
            {(user?.permissions?.includes("landlords_edit_commission") && hasPromotion) && <><Form.Group>
                <label htmlFor="promotionCommission">Promotion Commission (%)</label>
                <Form.Control type="number" className="form-control" id="promotionCommission" name="promotionCommission"
                              defaultValue={promotion?.commission} placeholder="x %" onChange={handleFieldChange}/>
            </Form.Group>
                <label htmlFor="promotionTime">Promotion Duration {promotion?.endDate ? ":" : "(months)"}</label>
                {promotion?.endDate ?
                    <span className={"ml-1 mb-2"}>
                        Until {moment(promotion?.endDate).format("DD/MM/YYYY")}
                    </span> :
                    <Select id="promotionTime" name={"promotionTime"}
                            menuPlacement="top"
                            className={"mb-3"}
                            placeholder={"Promotion Duration"}
                            onChange={o => handleFieldChange({target: {name: "promotionTime", value: o.value}})}
                            options={Array.from(Array(12).keys()).map((i) => ({value: i + 1, label: i + 1}))}/>}

            </>}
            <Form.Group>
                <label htmlFor="notes">Notes</label>
                <textarea className="form-control" id="notes" name={"notes"} rows="4" defaultValue={notes}
                          onBlur={handleFieldChange}/>
            </Form.Group>
            <Form.Group>
                <label htmlFor="password">New Password</label>
                <input type="password" className="form-control" id="password" name="password"
                       autoComplete={'new-password'}
                       placeholder="New Password" onChange={handleFieldChange}/>
            </Form.Group>
            {/*<div className="form-check">*/}
            {/*    <label className="form-check-label">*/}
            {/*        <input type="checkbox" className="form-check-input" id={"topLandlord"} name={"topLandlord"} defaultChecked={topLandlord} onChange={handleFieldChange}/>*/}
            {/*        <i className="input-helper" />*/}
            {/*        Top Landlord*/}
            {/*    </label>*/}
            {/*</div>*/}
        </Form>
    )
}

export default LandlordEditForm;
