import React, {useCallback, useMemo, useState} from 'react'
import {Editable, withReact, useSlate, Slate} from 'slate-react';

import {
    Editor,
    Transforms,
    createEditor
} from 'slate'

import bold from "./img/format_bold.svg";
import italic from "./img/format_italic.svg";
import underline from "./img/format_underlined.svg";
import bullet_list from "./img/format_list.svg";
import heading_one from "./img/format_heading-one.svg";
import heading_two from "./img/format_heading-two.svg";
import numbered from "./img/format_numbered-list.svg";
import isHotkey from "is-hotkey";
import moment from "moment";

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code',
}
const LIST_TYPES = ['numbered-list', 'bulleted-list']


const RichTextEditor = ({setValue, control, moreInfo, language, display, verificationDate}) => {

    const defaultParagraphs = {
        "en": [
            {
                type: 'paragraph',
                children: [{text: 'A line of text in a paragraph.'}],
            },
        ],
        "pt": [
            {
                type: 'paragraph',
                children: [{text: 'Uma linha de texto em um parágrafo.'}],
            },
        ],
        "es": [
            {
                type: 'paragraph',
                children: [{text: 'Una línea de texto en un párrafo.'}],
            },
        ],
        "it": [
            {
                type: 'paragraph',
                children: [{text: 'Una riga di testo in un paragrafo.'}],
            },
        ]
    }

    const initialValue = useMemo(() => {
        return language ? (moreInfo ? (moreInfo.validation?.[language] || moreInfo[language]) : defaultParagraphs[language]) : (moreInfo?.original ? moreInfo.original : defaultParagraphs["en"]);
    }, []);


    const renderElement = useCallback(props => <Element {...props} />, [])
    const [editor] = useState(() => withReact(createEditor()));


    const Element = ({attributes, children, element}) => {
        const style = {textAlign: element.align}
        switch (element.type) {
            case 'block-quote':
                return (
                    <blockquote style={style} {...attributes}>
                        {children}
                    </blockquote>
                )
            case 'bulleted-list':
                return (
                    <ul style={style} {...attributes}>
                        {children}
                    </ul>
                )
            case 'heading-one':
                return (
                    <h1 style={style} {...attributes}>
                        {children}
                    </h1>
                )
            case 'heading-two':
                return (
                    <h2 style={style} {...attributes}>
                        {children}
                    </h2>
                )
            case 'list-item':
                return (
                    <li style={style} {...attributes}>
                        {children}
                    </li>
                )
            case 'numbered-list':
                return (
                    <ol style={style} {...attributes}>
                        {children}
                    </ol>
                )
            default:
                return (
                    <p style={style} {...attributes}>
                        {children}
                    </p>
                )
        }
    }

    const renderLeaf = useCallback(props => {
        return <Leaf {...props} />
    }, []);


    const Leaf = ({attributes, children, leaf}) => {
        if (leaf.bold) {
            children = <strong>{children}</strong>
        }

        if (leaf.code) {
            children = <code>{children}</code>
        }

        if (leaf.italic) {
            children = <em>{children}</em>
        }

        if (leaf.underline) {
            children = <u>{children}</u>
        }
        return <span {...attributes}>{children}</span>
    }

    const isMarkActive = (editor, format) => {
        const marks = Editor.marks(editor)
        return marks ? marks[format] === true : false
    }


    const toggleMark = (editor, format) => {
        const isActive = isMarkActive(editor, format)

        if (isActive) {
            Editor.removeMark(editor, format)
        } else {
            Editor.addMark(editor, format, true)
        }
    }

    const MarkButton = ({format, icon, alt}) => {
        const editor = useSlate()
        return (
            <button
                className={"btn btn-primary ml-2"+(isMarkActive(editor, format) ? " active": "")}
                onClick={event => {
                    event.preventDefault();
                    toggleMark(editor, format);
                }}>
                <img src={icon} alt={alt}/>
            </button>
        )
    }

    const toggleBlock = (editor, format) => {
        const isActive = isBlockActive(editor, format)
        const isList = LIST_TYPES.includes(format)

        Transforms.unwrapNodes(editor, {
            match: n => LIST_TYPES.includes(n.type),
            split: true,
        })

        Transforms.setNodes(editor, {
            type: isActive ? 'paragraph' : isList ? 'list-item' : format,
        })

        if (!isActive && isList) {
            const block = {type: format, children: []}
            Transforms.wrapNodes(editor, block)
        }
    }


    const isBlockActive = (editor, format) => {
        const nodes = Editor.nodes(editor, {
            match: n => n.type === format,
        })
        return !!nodes.next().value
    }

    const BlockButton = ({format, icon, alt}) => {
        const editor = useSlate()
        return (
            <button
                className={"btn btn-primary ml-2" +(isBlockActive(editor, format) ? " active": "")}
                onClick={event => {
                    event.preventDefault();
                    toggleBlock(editor, format);
                }}>
                <img src={icon} alt={alt}/>
            </button>
        )
    }

    return (
        <div className={"RichTextEditor"} style={(!display ? {"display": "none"} : {})}>
            <Slate editor={editor} value={initialValue || defaultParagraphs["en"]}
                   onChange={v => {
                       if (v !== defaultParagraphs[language] && setValue)
                           setValue(v);
                   }}>
                <div className="btnContainer">
                    <MarkButton format={"bold"} icon={bold} alt={"bold"}/>
                    <MarkButton format={"italic"} icon={italic} alt={"italic"}/>
                    <MarkButton format={"underline"} icon={underline} alt={"underline"}/>
                    <BlockButton format={"bulleted-list"} icon={bullet_list} alt={"bulleted-list"}/>
                    <BlockButton format={"heading-one"} icon={heading_one} alt={"heading-one"}/>
                    <BlockButton format={"heading-two"} icon={heading_two} alt={"heading-two"}/>
                    <BlockButton format={"numbered-list"} icon={numbered} alt={"numbered-list"}/>
                    {verificationDate && <p className={"text-success"}>Last validation: {moment(verificationDate).format("DD/MM/YYYY hh:mm")}</p>}
                </div>
                {verificationDate && moreInfo && moreInfo.modificationDate && moment(moreInfo.modificationDate).isAfter(moment(verificationDate)) && <code>The landlord modified the text on the: {moment(moreInfo.modificationDate).format("DD/MM/YYYY hh:mm")}</code>}
                <div className="editorContainer">
                    <Editable
                        control={control}
                        renderElement={renderElement}
                        renderLeaf={renderLeaf}
                        onKeyDown={event => {
                            for (const hotkey in HOTKEYS) {
                                if (isHotkey(hotkey, event)) {
                                    event.preventDefault()
                                    const mark = HOTKEYS[hotkey]
                                    toggleMark(editor, mark)
                                }
                            }
                        }}
                    />
                </div>
            </Slate>
        </div>
    );
};

export default RichTextEditor;